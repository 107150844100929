/* News */

.section--news-index .container {
    max-width: 76rem;
}

/* News Filter Bar */

.news__filter {
    margin-bottom: 3rem;
}

.news__filter .choice-chips {
    justify-content: center;
}

.news__filter .choice-chips a {
    text-decoration: none;
    color: currentColor;
}

/* News Common Styles */

.section--news-index .title,
.section--news-landingpage .title {
    font-size: var(--text-700);
    text-align: center;
}
.section--news-index .subtitle,
.section--news-landingpage .subtitle {
    font-size: var(--text-600);
    text-align: center;
    margin-bottom: 3rem;
    margin-inline: auto;
    max-width: 32ch;
}

.section--news-landingpage .title {
    text-align: left;
}
.section--news-landingpage .subtitle {
    text-align: left;
    margin-inline: initial;
}

.news__explore {
    display: inline-flex;
    text-decoration: none;
    gap: 0.5rem;
    margin-top: 3rem;
    align-items: center;
    font-weight: 600;
    color: black;
}

.news__explore .icon {
    width: 1.25rem;
    height: 1.25rem;
}

.news__featured-image img {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--background-light);
}

.news__category {
    margin-block-start: 1.25rem;
    /* margin-block-end: 1rem; */
}

.news-detail__header a,
.news-detail__footer a {
    text-decoration: none;
    color: currentColor;
}

.news__title {
    font-size: var(--text-500);
    font-weight: 900;
    line-height: 1.2;
    margin-block: 1rem;
}

.news__introduction {
    margin-bottom: 1rem;
}

.news__date {
    font-weight: 900;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.news__items {
    display: grid;
    gap: clamp(1rem, 0.6rem + 2vw, 3rem);
    grid-template-columns: repeat(auto-fill, minmax(min(370px, 100%), 1fr));
}

.news__featured-image {
    overflow: hidden;
    border-radius: 1rem;
    will-change: transform;
}

.news__featured-image img {
    transition: transform 0.5s ease;
}

.news__item:hover .news__featured-image img {
    transform: scale(1.1, 1.1);
}

@media only screen and (max-width: 540px) {
    .news__items {
        grid-template-columns: 1fr;
    }
}

.news__category .pill {
    font-size: 1rem;
    /* border-radius: 0.6rem; */
    /* padding-inline: 0.6rem; */
    /* padding-bottom: 1px; */
}

/* News Langing Page */
.pill--hosting-news a,
.pill--company-news a,
.pill--business-news a,
.pill--private-news a,
.news__title a {
    color: currentColor;
    text-decoration: none;
}

.section--news-landingpage .news__items {
    grid-template-columns: repeat(auto-fill, minmax(min(240px, 100%), 1fr));
}

/* Custom Styles for every 6th News Item including the first */

.section--news-index .news__item:nth-of-type(7n + 1) {
    display: grid;
    flex-wrap: wrap;
    gap: clamp(1rem, 0.6rem + 2vw, 3rem);
    grid-column: 1 / -1;
    row-gap: 0;
    grid-template-columns: 1fr 370px;
}

.section--news-index .news__item:nth-of-type(7n + 1) .news__featured-image img {
    height: 100%;
}

.section--news-index .news__item:nth-of-type(7n + 1) .news__title {
    font-size: var(--text-600);
}

@media only screen and (max-width: 816px) {
    .section--news-index .news__item:nth-of-type(7n + 1) {
        grid-template-columns: 1fr;
    }
}

/* News Details Page */

/* News Navigation */

.section--news-detail .container {
    max-width: 76rem;
}

.news-detail__header {
    text-align: center;
}

.news-detail__header .news__date {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
    justify-content: center;
}

.news__streamfields {
    max-width: 64rem;
    margin-inline: auto;
}

.news__navigation {
    max-width: 64rem;
    margin-inline: auto;
}

.news-detail__header .news__title {
    font-size: clamp(2rem, 1.6rem + 2vw, 4rem);
    line-height: 1.2;
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.news-detail__header .news__featured-image {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.news__streamfields {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.nav-news__content {
    font-size: var(--text-300);
}

.nav-news__label {
    font-weight: 700;
}

.nav-news__title {
    font-weight: 400;
}

.news__navigation a {
    text-decoration: none;
    color: currentColor;
    transition: color 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    margin-bottom: 3rem;
}

.news__navigation a:hover {
    color: var(--primary-color);
}

.news__navigation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10%;
    position: relative;
}

.nav-news__item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nav-news__item--next {
    flex-direction: row-reverse;
    text-align: right;
}

.nav-news__image img {
    aspect-ratio: 1/1;
}

.nav-news__image svg {
    position: absolute;
    opacity: 0;
    z-index: 3;
    fill: #fff;
    will-change: transform;
    transition: transform 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition-delay: 60ms;
}

.nav-news__item--prev svg {
    transform: translateX(10px);
}

.nav-news__item--next svg {
    transform: translateX(-10px);
}

.nav-news__image {
    flex: 0 0 4.5rem;
    max-width: 4.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 100%;
    display: inline-flex;
    overflow: hidden;
}

.nav-news__item:hover svg {
    opacity: 1;
    transform: translateX(0);
}

.nav-news__image:after {
    position: absolute;
    opacity: 0;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #6c42e4, #00b0ea);
    will-change: opacity;
    transition: opacity 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border-radius: 100%;
}

.nav-news__item:hover .nav-news__image:after {
    opacity: 0.85;
}

@media only screen and (max-width: 700px) {
    .nav-news__title {
        display: none;
    }
}
