.price-flex {
  --price-size: 1;
  display: inline-flex;
}

.price-flex .price__integer,
.price-flex .price__currency,
.price-flex .price__fraction,
.price-flex .price__decimal-point {
  font-size: calc(2.625rem * var(--price-size));
  font-weight: 900;
  align-self: baseline;
}

.price-flex .price__duration {
  font-weight: 400;
  align-self: baseline;
  padding-left: calc(0.2rem * var(--price-size));
}

.price-grid {
  --price-size: 1;
  display: grid;
  grid-template-areas:
    "price-big-int price-fraction price-fraction "
    "price-big-int price-currency price-duration";
  grid-auto-rows: calc(1.2rem * var(--price-size));
}

.price-grid--inline {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.price-grid .price__integer {
  grid-area: price-big-int;
  align-self: center;
  font-size: calc(2.625rem * var(--price-size));
  font-weight: 900;
}

.price-grid .price__fraction,
.price-grid .price__currency,
.price-grid .price__duration {
  font-weight: 400;
}

.price-grid .price__integer {
  padding-right: calc(0.2rem * var(--price-size));
}

.price-grid .price__fraction {
  grid-area: price-fraction;
  font-size: calc(1rem * var(--price-size));
  justify-self: left;
}

.price-grid .price__currency {
  grid-area: price-currency;
  font-size: calc(0.875rem * var(--price-size));
}
.price-grid .price__duration {
  grid-area: price-duration;
  font-size: calc(0.875rem * var(--price-size));
  padding-left: calc(0.2rem * var(--price-size));
}

.price-grid .price__decimal-point {
  display: none;
}

/* Order Card Price Sizes */

.order__product-price {
  --price-size: 1;
  /* margin-block-start: 1rem; */
  margin-block-end: 1rem;
}
