/* career */

.section--career-index .container {
    max-width: 76rem;
}

/* career Filter Bar */

.career__filter {
    margin-bottom: 3rem;
}

.career__filter .choice-chips {
    justify-content: center;
}

.career__filter .choice-chips a {
    text-decoration: none;
    color: currentColor;
}

/* career Common Styles */

.section--career-index .title,
.section--career-landingpage .title {
    font-size: var(--text-700);
    text-align: center;
}
.section--career-index .subtitle,
.section--career-landingpage .subtitle {
    font-size: var(--text-600);
    text-align: center;
    margin-bottom: 3rem;
    margin-inline: auto;
    max-width: 32ch;
}

.section--career-landingpage .title {
    text-align: left;
}
.section--career-landingpage .subtitle {
    text-align: left;
    margin-inline: initial;
}

.career__explore {
    display: inline-flex;
    text-decoration: none;
    gap: 0.5rem;
    margin-top: 3rem;
    align-items: center;
    font-weight: 600;
    color: black;
}

.career__explore .icon {
    width: 1.25rem;
    height: 1.25rem;
}

.career__featured-image img {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--background-light);
}

.career__category {
    margin-block-start: 1.25rem;
    margin-block-end: 1rem;
}

.career-detail__header a,
.career-detail__footer a {
    text-decoration: none;
    color: currentColor;
}

.career__title {
    font-size: var(--text-500);
    font-weight: 900;
    line-height: 1.2;
    margin-block-start: 1.25rem;
    margin-block-end: 1rem;
}

.career__introduction {
    margin-bottom: 1rem;
}

.career__date {
    font-weight: 900;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.career__items {
    display: grid;
    gap: clamp(1rem, 0.6rem + 2vw, 3rem);
    grid-template-columns: repeat(auto-fill, minmax(min(370px, 100%), 1fr));
}

.career__featured-image {
    overflow: hidden;
    border-radius: 1rem;
}

.career__featured-image img {
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(1, 1);
    will-change: transform;
    transition: transform 0.5s ease;
}

.career__item:hover .career__featured-image img {
    transform: scale(1.1, 1.1);
}

@media only screen and (max-width: 540px) {
    .career__items {
        grid-template-columns: 1fr;
    }
}

.career__category .pill {
    font-size: 1rem;
    border-radius: 0.6rem;
    padding-inline: 0.6rem;
    padding-bottom: 1px;
}

/* career Langing Page */
.pill--hosting-career a,
.pill--company-career a,
.pill--business-career a,
.pill--private-career a,
.career__title a {
    color: currentColor;
    text-decoration: none;
}

.section--career-landingpage .career__items {
    grid-template-columns: repeat(auto-fill, minmax(min(240px, 100%), 1fr));
}

/* Custom Styles for every 6th career Item including the first */

.section--career-index .career__item:nth-of-type(7n + 1) {
    display: grid;
    flex-wrap: wrap;
    gap: clamp(1rem, 0.6rem + 2vw, 3rem);
    grid-column: 1 / -1;
    row-gap: 0;
    grid-template-columns: 1fr 370px;
}

.section--career-index
    .career__item:nth-of-type(7n + 1)
    .career__featured-image
    img {
    height: 100%;
}

.section--career-index .career__item:nth-of-type(7n + 1) .career__title {
    font-size: var(--text-600);
}

@media only screen and (max-width: 816px) {
    .section--career-index .career__item:nth-of-type(7n + 1) {
        grid-template-columns: 1fr;
    }
}

/* career Details Page */

/* career Navigation */

.section--career-detail .container {
    max-width: 76rem;
}

.career-detail__header {
    text-align: center;
}

.career-detail__header .career__date {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
    justify-content: center;
}

.career__streamfields {
    max-width: 64rem;
    margin-inline: auto;
}

.career__navigation {
    max-width: 64rem;
    margin-inline: auto;
}

.career-detail__header .career__title {
    font-size: clamp(2rem, 1.6rem + 2vw, 4rem);
    line-height: 1.2;
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.career-detail__header .career__featured-image {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.career__streamfields {
    margin-bottom: clamp(1.5rem, 1rem + 2.5vw, 4rem);
}

.nav-career__content {
    font-size: var(--text-300);
}

.nav-career__label {
    font-weight: 700;
}

.nav-career__title {
    font-weight: 400;
}

.career__navigation a {
    text-decoration: none;
    color: currentColor;
    transition: color 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    margin-bottom: 3rem;
}

.career__navigation a:hover {
    color: var(--primary-color);
}

.career__navigation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10%;
    position: relative;
}

.nav-career__item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.nav-career__item--next {
    flex-direction: row-reverse;
    text-align: right;
}

.nav-career__image img {
    aspect-ratio: 1/1;
}

.nav-career__image svg {
    position: absolute;
    opacity: 0;
    z-index: 3;
    fill: #fff;
    will-change: transform;
    transition: transform 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition-delay: 60ms;
}

.nav-career__item--prev svg {
    transform: translateX(10px);
}

.nav-career__item--next svg {
    transform: translateX(-10px);
}

.nav-career__image {
    flex: 0 0 4.5rem;
    max-width: 4.5rem;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 100%;
    display: inline-flex;
    overflow: hidden;
}

.nav-career__item:hover svg {
    opacity: 1;
    transform: translateX(0);
}

.nav-career__image:after {
    position: absolute;
    opacity: 0;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #6c42e4, #00b0ea);
    will-change: opacity;
    transition: opacity 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.nav-career__item:hover .nav-career__image:after {
    opacity: 0.85;
}

@media only screen and (max-width: 700px) {
    .nav-career__title {
        display: none;
    }
}
