/* Need to add to excisiting code */

input {
    transition-property: background-color;
    /* Input hat ansonsten eine Animation jedesmal wenn sich die Weite oder HÃ¶he Ã¤ndert */
}

/* Wizard - Base */

.wizard {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: 1rem;
    background-color: #f9f9f9;
    min-height: 100%;
}

/* Wizard - Aside Section */

.wizard .aside__container {
    overflow-y: auto;
    position: sticky;
    top: 0rem;
    padding: 2rem 1rem;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 2fr 1fr 0.5fr auto;
    row-gap: 2rem;
}

.wizard .aside__help * {
    margin: 0;
}

.wizard .aside__steps {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 1.5rem;
    font-size: var(--text-300);
    color: rgb(95, 95, 95);
}

.wizard .aside__steps .active {
    font-weight: 600;
    position: relative;
    font-size: var(--text-400);
    color: black;
}

.wizard .aside__steps-icon {
    display: inline-block;
    position: absolute;
    left: -1.5rem;
    top: 0.15rem;
    height: 1.5rem;
    width: 1.5rem;
}

/* Aside Help Section */

.wizard .help__title {
    font-size: var(--text-400);
    margin-bottom: 0;
}

/* Waypoint */

.wizard .aside__waypoint {
    display: none;
}

/* Wizard - Main Section */

.wizard main {
    background-color: #f2f2f2;
}

.wizard main {
    display: grid;
}

.wizard .main__header {
    display: flex;
    gap: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
    align-items: center;
}

/* main recede */

.wizard .main__recede svg {
    --recede-size: clamp(3.25rem, 2.85rem + 2vw, 5.25rem);
    width: var(--recede-size);
    height: var(--recede-size);
    transform: translateY(0.2rem);
}

.wizard .main__content {
    padding: 2rem 1rem;
    grid-area: main;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
}

.wizard .main__wrapper {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
}

/* Inputs with Floating labels */

.wizard .inputBox {
    position: relative;
    width: 100%;
}

.wizard .main__title {
    margin-bottom: 1.5rem;
}

.wizard .main__recede {
    margin-bottom: 1.5rem;
}

/* Default Input Text */

.wizard .inputBox input,
.wizard .inputBox select {
    width: 100%;
    padding-block-start: 1.6rem;
    height: 4.375rem;
    transform: translateY(0.5rem);
}

.wizard .inputBox select {
    font-size: 1.125rem;
    transition: all 0.2s ease-in-out;
}

.wizard .inputBox label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem;
    pointer-events: none;
    color: hsla(205, 0%, 35%, 0.6);
    font-size: 1.125rem;
    transition-duration: 0.3s;
    transform: translateY(0.725rem);
    white-space: nowrap;
    width: 100%;
}

.wizard .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

.wizard .inputBox input:not(:placeholder-shown)~label,
.wizard .inputBox input:focus~label {
    color: hsla(205, 100%, 35%, 0.65);
    color: hsla(205, 0%, 35%, 1);
    transform: scale(0.875) translateX(0.25rem);
    transform-origin: left;
}

/* Border on Focus */

.wizard .inputBox input:focus-within,
.wizard .inputBox input:focus-within:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px hsla(205, 100%, 49%, 1);
}

/* Hide Placeholder on init */

.wizard .inputBox input::placeholder {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
}

/* Placeholder */

.wizard .inputBox input:not(:placeholder-shown)::placeholder,
.wizard .inputBox input:focus::placeholder {
    opacity: 0.4;
    color: hsla(205, 0%, 35%);
}

/* Label after Typing */

.wizard .inputBox input:not(:placeholder-shown) {
    color: hsla(205, 0%, 0%, 1);
}

.wizard .inputBox input:not(:placeholder-shown)~label {
    color: hsla(205, 100%, 35%, 0.65);
}

/* Disabled Input */

.wizard .inputBox input:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.75;
}

.wizard .inputBox input:disabled~label {
    filter: grayscale(100%);
    opacity: 0.75;
}

.wizard .inputBox select:focus-visible {
    color: hsla(205, 100%, 35%, 1);
    border-color: hsla(205, 100%, 70%, 1);
    outline-color: hsla(205, 100%, 70%, 1);
    background-color: hsla(207, 82%, 98%, 1);
}

.wizard .inputBox select:focus-visible~label {
    color: hsla(205, 100%, 35%, 0.65);
}

.wizard .inputBox select~label {
    transform: scale(0.875) translateX(0.25rem);
    transform-origin: left;
}

/* Input Error */

.wizard .inputBox small {
    display: block;
    padding-block-start: 1rem;
}

.wizard .inputBox.error small {
    color: crimson;
}

/* Input Grid */

.wizard .main__input-grid {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
}

.wizard .input__group--flex {
    display: flex;
    flex-wrap: wrap;
}

.wizard .input__group {
    display: grid;
    gap: 1rem;
}

.wizard .input__group--1-1 {
    grid-template-columns: 1fr 1fr;
}

.wizard .input__group--1-2 {
    grid-template-columns: 1fr 2fr;
}

.wizard .input__group--1-3 {
    grid-template-columns: 1fr 3fr;
}

.wizard .aside__logo {
    width: clamp(2.5rem, 2rem + 2.5vw, 5rem);
    margin-left: clamp(0rem, -0.2rem + 1vw, 1rem);
}

/* Wizard - Basket Section */

.wizard .main__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        "main"
        "basket";
}

.wizard .basket__content {
    grid-area: basket;
    position: fixed;
    bottom: 0;
    width: 76%;
}

.wizard .basket-container {
    max-height: 70vh;
    display: flex;
    justify-content: center;
}

.wizard .basket {
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    width: 80%;
    max-width: 1024px;
    background-color: white;
    filter: drop-shadow(0 0 1.5rem hsla(0, 0%, 0%, 0.15));
}

.wizard .main__content {
    filter: blur(0rem);
    transition: filter 200ms;
}

.wizard .main__content.blur {
    filter: blur(0.5rem);
}

.wizard .basket__trigger {
    margin-top: -2rem;
    justify-content: center;
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 1;
}

.wizard .main__mobile-footer {
    display: none;
}

@media only screen and (max-width: 600px) {
    .wizard {
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 1fr;
        row-gap: 0;
    }

    /* Mobile Footer */

    .wizard .main__mobile-footer {
        display: block;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        text-align: center;
    }

    .wizard .main__mobile-footer a {
        text-decoration: none;
        opacity: 0.65;
    }

    .wizard .aside__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wizard .input__group {
        grid-template-columns: 1fr;
    }

    .wizard .aside__help,
    .wizard .aside__links,
    .wizard .aside__legal {
        display: none;
    }

    .wizard .aside__waypoint {
        display: flex;
    }

    .wizard .aside__steps {
        display: none;
    }

    /* .wizard .aside__steps ul {
      margin: 0;
    }
    .wizard .aside__steps li:not(.active) {
      display: none;
    }

    .wizard .aside__steps li svg {
      display: none;
    } */

    .wizard .basket-container {
        max-height: 60vh;
    }

    .wizard .aside__container {
        height: 100%;
        padding: 1rem;
    }

    .wizard .basket {
        width: 100%;
    }

    .wizard .basket__content {
        width: 100%;
    }

    .wizard .basket__full-view {
        display: none;
    }

    .wizard .basket__lite-view {
        display: block;
        height: 100%;
        /* overflow: auto; */
    }
}

@media only screen and (min-width: 600px) {
    .wizard .basket__full-view {
        display: block;
    }

    .wizard .basket__lite-view {
        display: none;
    }
}

.wizard .basket__lite-view .order-summary__header {
    display: block;
}

.wizard .basket__lite-view .order-summary__total {
    justify-content: space-between;
}

.wizard .basket__lite-view .order-summary__total--onetime {
    align-items: start;
}

.wizard .basket__details {
    display: none;
    overflow: auto;
    height: 100%;
}

.wizard .order-summary__header {
    margin-bottom: 0;
}

.wizard .order-summary__price--total {
    font-size: var(--text-500);
}

.wizard .main__body {
    margin-bottom: 1.5rem;
}

.wizard .main__content.has_basket {
    margin-bottom: 4rem;
}

.wizard details {
    border-bottom-color: transparent;
}

.wizard .main__footer {
    /* margin-top: 0rem; */
    /* margin-bottom: 1.5rem; */
}

.wizard #wizard_form {
    margin-bottom: 0rem;
}

.main__proceed {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.wizard .basket__details--show {
    display: block;
}

.wizard .basket__full-view .order-summary__item {
    display: block;
}

.wizard .basket__full-view .order-summary__primary-item,
.wizard .basket__full-view .order-summary__secondary-item {
    display: flex;
}

.wizard .order-summary__primary-item {
    /* color: red; */
    font-size: var(--text-400);
}

.wizard .order-summary__secondary-item {
    /* color: blue; */
    font-size: var(--text-300);
}

.wizard .order-summary__facturation-duration,
.wizard .order-summary__item--discount {
    /* font-size: 0.625rem; */
    font-weight: 400;
}

.wizard .order-summary__item--discount {
    color: #3ab667;
}

.wizard .order-summary__facturation-duration {
    color: rgb(100, 100, 100);
}

/* Font Weight Primary Items */
.wizard .order-summary__primary-item .order-summary__product-category {
    font-weight: 900;
}

.wizard .order-summary__primary-item .order-summary__price--onetime {
    font-weight: 900;
}

.wizard .order-summary__primary-item .order-summary__price--monthly {
    font-weight: 900;
}

/* Font Weight Secondary Items */

.wizard .order-summary__secondary-item .order-summary__title {
    font-weight: 600;
}

.wizard .order-summary__secondary-item .order-summary__price--onetime {
    font-weight: 600;
}

.wizard .order-summary__secondary-item .order-summary__price--monthly {
    font-weight: 600;
}

/* Mobile Basket View */

.wizard .order-summary__item--subtotal-onetime {
    display: flex;
}

.wizard .order-summary__item--subtotal-monthly {
    display: flex;
}

.wizard .basket__lite-view .order-summary__price--onetime {
    display: block;
}

.wizard .basket__lite-view .order-summary__product-category {
    border-bottom: 1px solid #ccc;
    padding-block: 1rem;
}

.wizard .basket__lite-view .order-summary__product-category:last-child {
    border-bottom: 0px solid #ccc;
    padding-block-end: 4rem;
}

.wizard .basket__lite-view .order-summary__product-category:first-child {
    border-bottom: 0px solid #ccc;
    padding-block-end: 0rem;
}

.wizard .order-summary__product-category--name {
    font-weight: 900;
    font-size: var(--text-500);
}

.wizard .basket__lite-view .order-summary__item {
    font-size: var(--text-300);
    border-bottom: none;
    justify-content: space-between;
    column-gap: 1rem;
    padding-block: 0.1rem;
}

.wizard .basket__lite-view .order-summary__title,
.wizard .basket__lite-view .order-summary__price--monthly,
.wizard .basket__lite-view .order-summary__price--onetime {
    font-weight: 500;
}

.wizard .basket__lite-view .order-summary__item--subtotal-monthly,
.wizard .basket__lite-view .order-summary__item--subtotal-onetime {
    font-size: var(--text-400);
    padding-top: 1rem;
}

.wizard .order-summary__item--subtotal-monthly .order-summary__title,
.wizard .order-summary__item--subtotal-monthly .order-summary__price--monthly,
.wizard .order-summary__item--subtotal-monthly .order-summary__price--onetime,
.wizard .order-summary__item--subtotal-onetime .order-summary__title,
.wizard .order-summary__item--subtotal-onetime .order-summary__price--monthly,
.wizard .order-summary__item--subtotal-onetime .order-summary__price--onetime {
    font-weight: 900;
}

.wizard .order-summary__lite-product-category--name {
    font-weight: 900;
}

.wizard .order-summary__lite-product-category {
    padding-block: 1rem;
}

.wizard .choice__shortcut {
    font-size: var(--text-300);
    display: flex;
    background-color: #e1fdff;
    width: calc(var(--text-300) * 2);
    height: calc(var(--text-300) * 2);
    line-height: calc(var(--text-300) * 2);
    border-width: 0.06rem;
    border-radius: 0.5rem;
    color: #0095ff;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    align-items: center;
    border-style: solid;
    border-color: #5dbaff;
}

/* Main Input Grid Button */

.wizard .main__input-grid button,
.wizard .main__input-grid .btn {
    border-radius: 0.5rem;
    background-color: #def1ff;
    border-color: #5dbaff;
    border-width: 0.06rem;
    gap: 1.25rem;
}

.wizard .main__input-grid button:hover,
.wizard .main__input-grid .btn:hover {
    background-color: #fff;
    box-shadow: 3px 3px rgb(93, 186, 255);
}

.wizard .input__group--flex button,
.wizard .input__group--flex .btn {
    justify-content: center;
}

.wizard .choice__icon {
    margin-left: auto;
}

.wizard .choice__label {
    text-align: left;
    color: #000622;
}

.wizard .choice__label :nth-child(2) {
    font-size: var(--text-200);
    /* opacity: 0.6; */
    font-weight: 500;
    color: #434860;
}

.wizard .main__section-title {
    font-size: var(--text-500);
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.wizard .order-summary__title svg {
    display: inline-flex;
    width: 1.2rem;
    height: 1.2rem;
    transform: translateY(0.2rem);
}

/* Choice Chips - Links */

.wizard .choice-chips {
    justify-content: center;
}

.wizard .choice-chip {
    cursor: pointer;
    color: #000;
    background-color: #fff;
    border: 2px solid #0000;
    border-radius: 32px;
    align-items: center;
    gap: 0.3rem;
    padding-block: 0.3rem 0.4rem;
    padding-inline: 0.8rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition-property: color, border-color;
    transition-duration: 0.25s;
    display: flex;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out, transform 0.1s ease-in-out,
        box-shadow 0.1s ease-in-out;
}

.wizard .choice-chip:hover {
    color: #000;
    background-color: #fff;
    border-color: #000;
    transform: translate(-3px, -3px);
    box-shadow: 3px 3px #000;
}

.wizard .choice-chip--active {
    border-color: var(--primary-color);
    color: var(--primary-color);
    background-color: #fff;
}

.wizard .choice-chip a {
    text-decoration: none;
    color: inherit;
}

.wizard .basket__trigger #Icon_ionic-ios-arrow-up {
    transform-box: fill-box;
    transform-origin: center;
    transform: translate(0.5rem, 0.5rem) rotate(0deg);
    fill: hsla(0, 0%, 0%, 1);
    transition: all 200ms;
}

.wizard .basket__trigger.open #Icon_ionic-ios-arrow-up {
    transform-box: fill-box;
    transform-origin: center;
    transform: translate(0.5rem, 0.7rem) rotate(180deg);
    fill: hsla(0, 0%, 0%, 1);
}

.wizard .basket__trigger #basket__trigger--ellipse {
    fill: hsl(0, 0%, 100%);
    transition: fill 200ms;
}

.wizard .basket__trigger svg {
    cursor: pointer;
}

/* Errors */

.wizard .errorlist li,
.wizard p.callout {
    background-color: rgba(0, 255, 255, 0.1);
    border-radius: 0.5rem;
    border: #0095ff solid 1px;
    color: rgba(0, 149, 255, 1);
    padding: 0.5rem 0.75rem;
    margin-block: 0.75rem;
    font-size: var(--text-300);
    font-weight: 500;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg class='feather feather-alert-circle' fill='none' height='1.5rem' stroke='rgba(0, 149, 255,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cline x1='12' x2='12' y1='8' y2='12'/%3E%3Cline x1='12' x2='12.01' y1='16' y2='16'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0.6rem 0.6rem;
    padding-left: 2.6rem;
}

.wizard p.callout {
    margin-bottom: 1.5rem;
}

/* Benne Magic */

.STYLESHEETS-TODO {
    background-color: deeppink !important;
    color: yellow !important;
    animation: flickerAnimation 0.75s infinite;
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

#content {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.25s ease-out;
}

#content.htmx-swapping {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.25s ease-out;
}

#content.htmx-request {
    opacity: 0.1;
    transition: all 300ms linear;
    cursor: not-allowed;
    pointer-events: none;
}

#content.htmx-added {
    opacity: 0;
}

@keyframes basketUpdate {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

#basket {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.5s ease-out;
}

#basket.hidden {
    opacity: 0;
    transform: translateY(150px);
    transition: all 0.5s ease-out;
}

.wizard button.btn--text-link {
    font-size: var(--text-300);
    border: 0;
    padding: 0;
    gap: 0.75rem;
    text-decoration: underline;
    text-decoration-color: var(--primary-text-decoration-color);
    transition: text-decoration-color 0.2s;
}

.wizard button.btn--text-link:hover {
    box-shadow: none;
    transform: none;
    color: var(--primary-link-color);
    text-decoration-color: var(--primary-color);
    background-color: transparent;
}

.wizard button * {
    pointer-events: none;
}

.wizard button#choice-0 {
    margin-bottom: 1.5rem;
}

.wizard main p {
    max-width: 59ch;
}

/* Phonenumber a tag */

.wizard a[href^="tel"] {
    color: inherit;
    /* text-decoration-color: transparent; */
}

.wizard a {
    font-weight: 500;
    color: inherit;
    text-decoration-color: transparent;
    transition: all 0.2s;
}

.wizard a:hover {
    color: var(--primary-color);
}

.wizard .aside__links {
    font-size: var(--text-300);
}

.wizard .aside__legal {
    font-size: var(--text-300);
}

/* Checkout Header */

.wizard .checkout__header-title {
    font-size: var(--text-500);
    font-weight: 800;
}

.wizard .checkout__header-product {
    font-size: var(--text-600);
    font-weight: 800;
}

.wizard .checkout__netneutrality-info {
    margin-bottom: 1rem;
}

.wizard .checkout__netneutrality-info a {}

.wizard .checkout__address-title {
    font-weight: 700;
}

.wizard .checkout__address {
    margin-bottom: 1rem;
}

.wizard .checkout__header-congratulations {
    margin-bottom: 1rem;
}

.wizard .checkout__header-info {
    display: flex;
    font-weight: 900;
    gap: 1rem;
}

.wizard .checkout__header-leading-icon {
    display: flex;
    gap: 0.25rem;
}

.wizard .checkout__netneutrality-info a {
    font-size: var(--text-300);
    text-decoration-color: black;
}

.wizard .checkout__address {
    font-size: var(--text-300);
}

.wizard .order-summary__lite-product-category .order-summary__item {
    padding-block: 0.1rem;
    font-size: var(--text-300);
}

.wizard .order-summary__lite-product-category .order-summary__item--subtotal-onetime {
    margin-top: 1rem;
}

.wizard .order-summary__item--subtotal-monthly {
    margin-top: 1rem;
}

.wizard .order-summary__item--subtotal-monthly .order-summary__title,
.wizard .order-summary__item--subtotal-onetime .order-summary__title {
    color: black;
    font-weight: 900;
}

.wizard .main__footer-checkout-prices {
    text-align: right;
}

.wizard .checkout-lottie-animation {
    display: flex;
    justify-content: center;
}

.wizard .checkout-lottie-animation-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.wizard .checkout-lottie-animation {
    height: 10rem;
}

.wizard .checkout-lottie-subtitle {
    transform: translateY(-1.25rem);
}

.wizard .main__footer-checkout-prices .footnotes {
    text-align: right;
    margin-bottom: 2rem;
}

/* Mobile Progress Circle */

.wizard .chart-container {
    width: 2.25rem;
    display: inline-block;
}

.wizard .single-chart {
    width: 100%;
}

.wizard .circular-chart {
    display: block;
    position: relative;
}

.wizard .circular-chart .circle-bg {
    fill: none;
    stroke: #d5d6d8;
    stroke-width: 4;
}

.wizard .circular-chart .circle {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress 1s ease forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 50;
    }
}

.wizard .circular-chart .circle {
    stroke: #333a47;
}

.wizard .single-chart text {
    text-anchor: middle;
    font-weight: 700;
}

.wizard .circular-chart-value {
    font-size: 0.7em;
}

.wizard .circular-chart-unit {
    font-size: 0.4em;
}

/* Dropzone Styles */

.wizard #upload-dropzone {
    border-radius: var(--default-radius);
    border: 2px dashed rgb(0, 135, 247);
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
}

.wizard #upload-dropzone.dz-started {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .wizard #upload-dropzone.dz-started {
        grid-template-columns: 1fr 1fr;
    }
}

.wizard .dz-message p {
    margin-bottom: 0;
}

.wizard .dz-message .footnotes {
    text-align: center;
}

.wizard .dropzone-delete-icon {
    width: 2rem;
    height: 2rem;
    fill: white;
    opacity: 0.75;
    transition: all 200ms ease;
    transform: rotate(0deg);
}

.wizard .dropzone .dz-preview .dz-details {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.5rem;
    display: flex;
    justify-content: right;
    align-items: flex-start;
}

.wizard .dropzone-delete-icon:focus,
.wizard .dropzone-delete-icon:hover {
    opacity: 1;
    transform: rotate(90deg);
}

.wizard .dropzone.dz-clickable * {
    cursor: pointer;
}

.wizard .dropzone .dz-preview {
    margin: 0;
}

.wizard .dropzone .dz-preview .dz-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.wizard .dropzone .dz-preview .dz-image img {
    width: 100%;
    height: 100%;
}

.wizard .dropzone .dz-preview.dz-success .dz-success-mark {
    animation: passing-through 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.wizard .dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    margin-top: 0;
    width: 100%;
    margin-left: 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1.2rem;
}

.wizard .dropzone .dz-preview .dz-progress .dz-upload {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    transition: width 300ms ease-in-out;
    overflow: hidden;
}

.wizard .dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
}

/* Default textarea Text */

.wizard .inputBox textarea {
    width: 100%;
    padding-block-start: 1.6rem;
    height: 4.375rem;
    transform: translateY(0.5rem);
    transition-property: background-color, border-color, box-shadow;
    min-height: 12rem;
    resize: none;
}

.wizard .inputBox textarea:not(:placeholder-shown)~label,
.wizard .inputBox textarea:focus~label {
    color: hsla(205, 0%, 35%, 1);
    transform: scale(0.875) translateX(0.25rem) translateY(-0.15rem);
    transform-origin: left;
}

/* Border on Focus */

.wizard .inputBox textarea:focus-within,
.wizard .inputBox textarea:focus-within:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px hsla(205, 100%, 49%, 1);
}

/* Hide Placeholder on init */

.wizard .inputBox textarea::placeholder {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
}

/* Placeholder */

.wizard .inputBox textarea:not(:placeholder-shown)::placeholder,
.wizard .inputBox textarea:focus::placeholder {
    opacity: 0.4;
    color: hsla(205, 0%, 35%);
}

/* Label after Typing */

.wizard .inputBox textarea:not(:placeholder-shown)~label {
    color: hsla(205, 100%, 35%, 0.65);
}

.wizard .inputBox textarea:not(:placeholder-shown),
.wizard .inputBox textarea:focus {
    outline: none;
}

.wizard .inputBox textarea:focus {
    background-color: #f5fafe;
    border-color: #0091f8;
}

/* Wizard Language Switcher */

.wizard .language-switcher {
    display: flex;
    margin-top: clamp(2rem, 1.4rem + 3vw, 5rem);
}

.wizard .language-switcher ul {
    text-transform: uppercase;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    margin: 0;
}

.wizard .language-switcher ul li {
    position: relative;
    font-size: var(--text-300);
}

.wizard .language-switcher ul li a {
    padding: 0.4rem;
    color: hsla(0, 0%, 0%, 0.4);
    font-weight: 600;
}

.wizard .language-switcher ul li a:hover,
.wizard .language-switcher ul li a.active:hover,
.wizard .language-switcher ul li a:focus,
.wizard .language-switcher ul li a.active:focus {
    color: var(--primary-color);
}

.wizard .language-switcher ul li a.active {
    color: hsla(0, 0%, 0%, 1);
}

.wizard .language-switcher ul li::after {
    content: "â€¢";
    position: absolute;
    color: hsla(0, 0%, 0%, 0.2);
    font-weight: 400;
    transform: translateY(-3%) translateX(-50%);
}

.wizard .language-switcher ul li:last-child::after {
    content: "";
}

/* leading-checkbox */

.wizard .leading-checkbox__container {
    display: flex;
    align-items: flex-start;
    gap: 0.6rem;
    background-color: white;
    border-radius: 0.51rem;
    padding: 1rem;
    font-size: var(--text-300);
    margin-bottom: 1rem;
}

.wizard .leading-checkbox__container:last-child {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.wizard .main__footer-checkout-confirm button {
    margin-top: 1rem;
}

.wizard .main__footer-checkout-confirm .checkbox input:focus {
    border: 1px solid transparent;
    background-color: aliceblue;
}

.wizard .main__footer-checkout-confirm .checkbox {
    --background: #fff;
    --border: #d1d6ee;
    --border-hover: #bbc1e1;
    --border-active: var(--primary-color);
    --tick: #fff;
    position: relative;
}

.wizard .main__footer-checkout-confirm .checkbox input,
.wizard .main__footer-checkout-confirm .checkbox svg {
    width: 21px;
    height: 21px;
    display: block;
}

.wizard .main__footer-checkout-confirm .checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 0.3rem;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}

.wizard .main__footer-checkout-confirm .checkbox input:hover {
    --s: 2px;
    --b: var(--border-hover);
}

.wizard .main__footer-checkout-confirm .checkbox input:checked {
    --b: var(--border-active);
}

.wizard .main__footer-checkout-confirm .checkbox svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
}

.wizard .main__footer-checkout-confirm .checkbox.path input:checked {
    --s: 2px;
    transition-delay: 0.4s;
}

.wizard .main__footer-checkout-confirm .checkbox.path input:checked+svg {
    --a: 16.1 86.12;
    --o: 102.22;
}

.wizard .main__footer-checkout-confirm .checkbox.path svg {
    stroke-dasharray: var(--a, 86.12);
    stroke-dashoffset: var(--o, 86.12);
    transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.wizard .main__footer-checkout-confirm .checkbox.bounce {
    --stroke: var(--tick);
}

.wizard .main__footer-checkout-confirm .checkbox.bounce input:checked {
    --s: 11px;
}

.wizard .main__footer-checkout-confirm .checkbox.bounce input:checked+svg {
    -webkit-animation: bounce 0.4s linear forwards 0.2s;
    animation: bounce 0.4s linear forwards 0.2s;
}

.wizard .main__footer-checkout-confirm .checkbox.bounce svg {
    --scale: 0;
}

.wizard .main__footer-checkout-confirm a {
    color: var(--primary-link-color);
    text-decoration-color: var(--primary-text-decoration-color);
    transition: text-decoration-color 0.2s;
}

@-webkit-keyframes bounce {
    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

/* Dropzone Demo Installation Grid  */

.wizard .demo-installation-grid {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-bottom: 2rem;
}

.wizard .demo-installation-grid img {
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0.5rem;
}

/* Order Wizard Leading Animation */

.wizard .wizardstep__leading-animation {
    display: flex;
    justify-content: center;
}

.wizard .wizardstep__leading-animation>* {
    max-width: clamp(12rem, 9.6rem + 12vw, 24rem);
}

.wizard .wizardstep__with-leading-animation {
    width: 100%;
}

.wizard .wizardstep__with-leading-animation div {
    display: flex;
    justify-content: center;
}

.wizard .wizardstep__with-leading-animation lottie-player {
    max-width: clamp(10rem, 8rem + 10vw, 20rem);
}

.wizard .order-summary__total--monthly .order-summary__price--label,
.wizard .order-summary__total--onetime .order-summary__price--label {
    font-size: var(--text-200);
}

.wizard .order-summary__lite-product-category {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}

.wizard .order-summary__lite-product-category:last-child {
    margin-bottom: 4rem;
    border-bottom-color: transparent;
}
