.section {
    padding-inline: var(--section-padding-inline);
    padding-block: var(--section-padding-block);
}

.section--hardware-index {
    padding-inline: 0;
}

.section--hardware-index .container {
    padding-inline: var(--section-padding-inline);
}

.section--hardware-index .hardware__category-wrapper {
    background: linear-gradient(180deg,
            #fff,
            var(--background-light) 77%,
            #fff 0);
}

.section--order .swiper-horizontal {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(-50vw + 50%);
}

.section--hero-tictoc {
    background: linear-gradient(180deg,
            rgba(245, 245, 247, 1) 50%,
            rgba(255, 255, 255, 0) 100%);
}

.section--has-transparent-header {
    padding-block-start: clamp(3rem, -2.2rem + 26vw, 16rem);
    padding-block-end: clamp(4rem, 3.2rem + 4vw, 8rem);
}

.section--order {
    background-color: var(--background-light);
}

.section--speed-comparison {
    background-color: var(--background-light);
}

.section--footnotes {
    background-color: var(--background-light);
}

.section--decoder {
    text-align: center;
}

.section--tv-devices {
    background-color: var(--background-light);
}

.section--channels {
    background-color: var(--background-light);
    overflow: hidden;
}

.section--channels .cta-button {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.section--features .cta-button {
    margin-top: 2rem;
}

.order-questions__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.order-questions__title {
    text-align: center;
}

.order-questions-item__title {
    font-size: var(--text-600);
    font-weight: 700;
}

.order-questions-item__subtitle {
    font-size: var(--text-500);
    font-weight: 500;
}

.promo-cards-list {
    display: flex;
    gap: 2rem;
}

.promo-card-list__item {
    text-align: center;
}

.section--tvfeatures {
    text-align: center;
}

.tvfeatures__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.section--tv-devices,
.section--order-questions,
.section--features {
    text-align: center;
}

.list {
    display: flex;
    gap: 1rem 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.list-item {
    flex: 0 0 300px;
}

/* Hero Image */

.container__hero {
    display: grid;
    max-width: 76rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
}

.hero__title {
    grid-column: 1/2;
}

.hero__subtitle {
    grid-column: 1/2;
}

.hero__cta-button {
    grid-column: 1/2;
    align-self: center;
    justify-self: start;
}

.hero__image-container {
    display: grid;
    grid-column: 2/2;
    grid-row: 1/4;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "overlap";
    align-self: end;
}

.hero__badge-large,
.hero__badge-small {
    align-self: flex-end;
    grid-area: overlap;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    will-change: transform;
    transition: transform 750ms ease;
}

.hero__badge-small {
    max-height: 160px;
    max-width: 160px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(96, 142, 159, 0.37);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 1);
}

.hero__badge-large {
    z-index: 3;
    justify-self: right;
    max-height: 250px;
    max-width: 250px;
    color: #7ce4eb;
    background-color: rgba(0, 31, 92, 1);
    box-shadow: 0 8px 32px 0 rgba(31, 83, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0);
}

.hero__badge-large a {
    color: currentColor;
}

.hero__badge-large:hover,
.hero__badge-small:hover {
    transform: scale(1.1) rotate(6deg);
    transition: transform 250ms;
}

.hero__circle {
    grid-area: overlap;
}

.hero__circle-backdrop {
    grid-area: overlap;
    z-index: 1;
    align-self: center;
    justify-self: center;
    /* position: absolute; */
    /* max-width: 480px; */
    /* max-height: 480px; */
}

.hero__circle-image {
    grid-area: overlap;
    z-index: 2;
    /* bottom: 1rem; */
    position: relative;
    justify-self: center;
    /* max-height: 500px; */
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    /* width: fit-content; */
}

@media only screen and (max-width: 750px) {
    .hero__circle-image {
        /* bottom: 1rem; */
    }

    .container__hero {
        display: grid;
        row-gap: 0rem;
        max-width: 76rem;
        grid-template-columns: 1fr;
    }

    .hero__image-container {
        grid-column: 1/1;
    }

    .hero__circle-image {
        /* max-height: 450px; */
        /* padding: 2rem 2rem 1.5rem 2rem; */
        /* padding-inline: 2rem; */
        /* bottom: clamp(1rem, 2.38rem + -1.15vw, 2.15rem); */
    }

    .hero__circle-backdrop {
        /* max-width: 425px; */
        /* max-height: 425px; */
        /* padding: 1.5rem; */
    }

    .hero__badge-large {
        max-height: 220px;
        max-width: 220px;
    }
}

/* wide image hero section */

.section--hero-wide.section--has-transparent-header {
    padding-bottom: 0;
    padding-top: 0;
}

.section--hero-wide--outro {
    /* background-color: bisque; */
    padding-top: 0;
    padding-bottom: clamp(4rem, 3.9rem + 0.5vw, 4.5rem);
}

.hero-wide--outro__subheadline {
    margin: 0;
}

.hero-wide__content {
    padding-top: 7rem;
    padding-bottom: clamp(6rem,
            5.428571428571429rem + 2.857142857142857vw,
            8rem);
    padding-left: 1rem;
    padding-right: 1rem;
}

.container__hero-wide {
    text-align: center;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
    max-width: 90rem;
    justify-content: center;
}

.hero-wide__backdrop {
    z-index: -1;
}

.hero-wide__backdropimage {
    height: 100%;
    width: 100vw;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    object-position: center top;
}

@media only screen and (min-width: 1330px) {
    .hero-wide__backdropimage {
        object-fit: contain;
        object-position: center top;
    }
}

.hero-wide__title {
    max-width: 15ch;
    justify-content: center;
    font-size: clamp(2.5rem, 2.1rem + 2vw, 4.5rem);
}

.hero-wide--outro__subheadline {
    text-align: center;
    max-width: 45ch;
    font-size: clamp(1.875rem, 1.65rem + 1.125vw, 3rem);
}

.container__hero-wide--outro {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
}

/* Hero Section Solid Color with Image */

.section--hero-colorsplit {
    padding-inline: 0;
    padding-top: 2rem;
    padding-bottom: 0;
}

.container__hero-colorsplit {
    max-width: 160rem;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    background-color: #004cff;
}

.hero-colorsplit__content-wrapper {
    grid-row: 1;
    grid-column: 1/-1;
    z-index: 2;
}

.hero-colorsplit__content-container {
    max-width: calc(76rem + 2rem);
    margin: auto;
    padding-inline: var(--default-inline-padding);
    height: 100%;
}

.hero-colorsplit__content-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 2rem 1fr 2rem;
    height: 100%;
}

.hero-colorsplit__backdrop {
    z-index: 1;
    grid-row: 1;
    grid-column: 2;
    /* aspect-ratio: 16/8; */
}

.hero-full__backdropimage {
    /* height: clamp(14rem, 10.4rem + 18vw, 32rem); */
    height: clamp(16rem, 12.8rem + 16vw, 32rem);
    /* max-height: 100px; */
}

.hero-colorsplit__content {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 3;
    grid-row: 2/2;
    grid-column: 1/8;
}

.hero-colorsplit__overlaypattern {
    z-index: 2;
    grid-row: 1;
    grid-column: 3/9;
    grid-row: 2/2;
    align-items: center;
    display: flex;
}

.hero-colorsplit__title,
.hero-colorsplit__subtitle {
    text-shadow: 2px 2px 0px #004cff;
}

.hero-colorsplit__title {
    font-size: clamp(3rem, 2.7rem + 1.5vw, 4.5rem);
    font-weight: 900;
    line-height: 1;
}

.hero-colorsplit__subtitle {
    font-size: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: 2rem;
    max-width: 43ch;
}

.hero-colorsplit__overlaypattern svg {
    color: #00cbff;
    width: 100%;
    height: 100%;
}

/* mediablock shadow overlay */

.media-block--shadow-overlay {
    position: relative;
}

.media-block--shadow-overlay img {
    position: inherit;
    z-index: -2;
    width: 100%;
}

.media-block--shadow-overlay::before,
.media-block--shadow-overlay::after {
    content: "";
    position: absolute;
    inset: 0;
}

.media-block--shadow-overlay::before {
    background: linear-gradient(90deg,
            rgba(0, 76, 255, 1) 0%,
            rgba(255, 255, 255, 0) 20%),
        linear-gradient(90deg,
            rgba(0, 76, 255, 1) 0%,
            rgba(255, 255, 255, 0) 40%);
    z-index: -1;
    mix-blend-mode: normal, hard-light;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .container__hero-colorsplit {
        grid-template-columns: 1fr;
        grid-template-rows: 0.5fr 1fr;
    }

    .hero-colorsplit__content-wrapper {
        grid-column: 1;
        grid-row: 1/-1;
    }

    .hero-colorsplit__backdrop {
        grid-column: 1;
        grid-row: 1/2;
        /* aspect-ratio: 16 / 7; */
    }

    .media-block--shadow-overlay::before {
        background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.4) 0%,
                rgba(0, 0, 0, 0) 30%),
            linear-gradient(0deg,
                rgba(0, 76, 255, 0.25) 0%,
                rgba(255, 255, 255, 0) 40%);
        z-index: -1;
        mix-blend-mode: normal, hard-light;
        overflow: hidden;
    }

    .hero-colorsplit__content-grid {
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(6, 1fr);
    }

    .hero-colorsplit__content {
        grid-row: 4/-1;
        grid-column: 1/-1;
        padding-inline: 1rem;
    }

    .hero-colorsplit__overlaypattern {
        grid-column: 2/6;
        grid-row: 2/6;
        align-items: center;
        display: flex;
    }
}

@media screen and (max-width: 599px) {
    .hero-colorsplit__backdrop {
        /* aspect-ratio: 16 / 9; */
    }
}

@media screen and (min-width: 600px) {
    .hero-full__backdropimage {
        height: clamp(24rem, 22.4rem + 8vw, 32rem);
    }

    .hero-colorsplit__backdrop {
        /* aspect-ratio: 16 / 10; */
        /* height: 100%; */
    }
}

@media screen and (min-width: 1024px) {
    .hero-colorsplit__backdrop {
        /* aspect-ratio: 16 / 8; */
    }
}

/* Call To Action Button Link Style */

.cta-button a {
    text-decoration: none;
    color: currentColor;
}

/* Channel Categories */

.section--channel-category:nth-child(even) {
    background-color: var(--background-light);
}

/* Availability Check - Nothing Available */

.section__nothing-available {
    padding-bottom: 3rem;
}

.section__nothing-available p {
    margin-bottom: 0;
}

.nothing-available__wrapper {
    display: flex;
    background: linear-gradient(45deg, #6c42e4 0%, #00b0ea 100%);
    border-radius: 1rem;
    color: white;
    padding: clamp(0.5rem, 0.2rem + 1.5vw, 2rem) clamp(1rem, 0.6rem + 2vw, 3rem);

    gap: 1rem;
}

.nothing-available__headline {
    line-height: 1.2;
    font-size: var(--text-800);
    margin-bottom: var(--spacing-400);
    font-weight: 700;
}

.nothing-available__note {
    font-weight: 300;
    line-height: 1.2;
    font-size: var(--text-600);
    margin-bottom: var(--spacing-600);
}

.nothing-available__tip {
    font-weight: 300;
    line-height: 1.2;
    font-size: var(--text-400);
    margin-bottom: var(--spacing-400);
}

.nothing-available__note strong {
    font-weight: 900;
}

.nothing-available__tip strong {
    font-weight: 900;
}

.nothing-available__contact {
    font-size: var(--text-500);
    line-height: 1.2;
    margin-bottom: var(--spacing-500);
}

.nothing-available__animation {
    display: none;
}

@media screen and (min-width: 992px) {
    .nothing-available__animation {
        display: block;
    }
}

/* Decoder Section */

.decoder__header {
    display: flex;
    flex-direction: row-reverse;
    gap: 5rem;
}

@media only screen and (max-width: 720px) {
    .decoder__header {
        flex-wrap: wrap;
        row-gap: 1rem;
    }
}

.section--decoder .subtitle,
.decoder__header {
    margin-bottom: clamp(3rem, 2.4rem + 3vw, 6rem);
}

.section--decoder .subtitle {
    max-width: 30ch;
    margin-inline: auto;
}

.decoder__wrapper {
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1.5rem;
}

.decoder__image {
    flex: 1 1 60%;
}

.decoder__more-apps {
    font-weight: 700;
}

.decoder__apps {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 2rem;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.decoder__app {
    animation-name: fading-apps;
    animation-duration: 12.8s;
    animation-iteration-count: infinite;
    opacity: 0.8;
}

.decoder__app:nth-of-type(8) {
    animation-delay: 0s;
}

.decoder__app:nth-of-type(7) {
    animation-delay: 0.8s;
}

.decoder__app:nth-of-type(2) {
    animation-delay: 1.6s;
}

.decoder__app:nth-of-type(1) {
    animation-delay: 2.4s;
}

.decoder__app:nth-of-type(3) {
    animation-delay: 3.2s;
}

.decoder__app:nth-of-type(6) {
    animation-delay: 4s;
}

.decoder__app:nth-of-type(4) {
    animation-delay: 4.8s;
}

.decoder__app:nth-of-type(5) {
    animation-delay: 5.6s;
}

.decoder__app:nth-of-type(9) {
    animation-delay: 6.4s;
}

.section--decoder .container {
    max-width: 76rem;
}

@keyframes fading-apps {
    0% {
        opacity: 0.8;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.8;
    }
}

/* get the app wrapper */

.app-store-badges__wrapper {
    gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

/* Quickcheck Form Section */

.section--quickcheck {
    padding-top: 0;
    background-color: transparent !important;
}

.section--quickcheck .container {
    position: relative;
}

.section--quickcheck form {
    margin-bottom: 0;
}

.section--quickcheck form select {
    z-index: 1;
}

.quickcheck__title {
    margin-bottom: 1.5rem;
}

.quickcheck__backdrop {
    width: 500px;
    height: 500px;
    position: absolute;
    right: -25%;
    top: -50%;
    opacity: 0.05;
    pointer-events: none;
    z-index: -1;
}

.quickcheck__card {
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
    border-radius: 1rem;
    padding: clamp(1rem, 0.6rem + 2vw, 3rem);
    background-color: hsla(240, 10%, 95%, 0.5);
}

/* TV Devices */

.section--tv-devices .image {
    margin-inline: auto;
}

.section--tv-devices .container {
    max-width: 76rem;
}

.section--tv-devices .list {
    column-gap: 3rem;
}

.section--tv-devices .list-item {
    flex: 0 0 260px;
}

/* Webmail Login */

.section--webmail-login h2 {
    margin-bottom: 0;
}

.section--webmail-login {
    background-position: center calc(clamp(4rem, 3.2rem + 4vw, 8rem) + 3rem);
    background-repeat: no-repeat, no-repeat;
    background-size: 1280px, 100%;
}

.section--webmail-login .container {
    max-width: inherit;
    text-align: center;
}

.section--webmail-login form {
    background-color: white;
    padding: 2rem;
    display: flex;
    border-radius: 1rem;
    flex-direction: column;
    text-align: left;
    max-width: 32rem;
    margin-inline: auto;
    margin-block: 2rem;
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
}

.section--webmail-login input {
    margin-block: 0.5rem 1rem;
}

.section--webmail-login button {
    justify-content: center;
}

.section--webmail-login .note {
    text-align: left;
    max-width: 32rem;
    margin-inline: auto;
    font-size: var(--text-300);
}

.section--webmail-login button {
    margin-top: 1rem;
}

/* Error Message */

.section--webmail-login .error-msg {
    max-width: 32rem;
    margin-inline: auto;
}

/* Contact Sales Card */

.container--sales-contact {
    display: grid;
    background-color: var(--background-light);
    padding: 2.25rem 2.5rem;
    border-radius: 1rem;
    row-gap: 1rem;
    column-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    font-weight: 600;
}

.sales-card {
    display: flex;
    gap: 1rem;
}

@media only screen and (max-width: 600px) {
    .sales-card {
        flex-wrap: wrap;
    }
}

.sales-contact__title {
    font-size: var(--text-500);
    font-weight: 900;
    color: #2e54a1;
    line-height: 1.2;
    margin-bottom: 1.5rem;
}

.sales-contact__subtitle {
    font-size: var(--text-400);
    color: #14afe6;
    line-height: 1.2;
    text-wrap: balance;
}

.sales-card__name {
    font-size: var(--text-500);
    font-weight: 900;
}

.sales-card__title {
    opacity: 0.8;
    margin-bottom: 1rem;
}

.sales-card__avatar {
    aspect-ratio: 1/1;
    width: clamp(5rem, 4.7rem + 1.5vw, 6.5rem);
    height: clamp(5rem, 4.7rem + 1.5vw, 6.5rem);
    border-radius: 50%;
}

.sales-card__email,
.sales-card__phone {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

/* Tools */

.section--tools form {
    gap: 1rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
}

/* Hardware Index */

.section--hardware-index .container {
    max-width: 76rem;
}

/* hardware Common Styles */

.hardware__content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
    padding: 1rem;
    flex-grow: 1;
}

.hardware__content .price-grid {
    margin-block: 1rem;
}

.section--hardware-index>.container {
    /* background-color: red; */
    max-width: 64ch;
    margin-inline: auto;
    text-align: center;
}

.hardware-category__title {
    font-size: var(--text-600);
    margin-bottom: 1.5rem;
}

.hardware-category__description {
    font-size: var(--text-500);
    margin-bottom: 3rem;
    max-width: 42ch;
}

.hardware__featured-image img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: contain;
    background-color: var(--background-light);
}

.hardware__item a {
    color: currentColor;
    text-decoration: none;
}

.hardware__title {
    font-size: var(--text-500);
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 1rem;
    flex-grow: 1;
}

.section--hardware-index .hardware__title {
    margin-bottom: 0rem;
}

.section--hardware-index .hardware__introduction>* {
    margin-block-end: 0;
}

.hardware__date {
    font-weight: 900;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.hardware__items {
    display: grid;
    gap: clamp(1rem, 0.6rem + 2vw, 3rem);

    grid-template-columns: repeat(auto-fill, minmax(min(240px, 100%), 1fr));
}

.hardware__featured-image {
    overflow: hidden;
    flex-grow: 0;
    border-radius: 1rem 1rem 0rem 0rem;
}

.hardware__featured-image img {
    /* width: 100%; */
    /* height: 100%; */
    /* display: block; */
    padding-top: 0.5rem;
    /* transform: scale(1, 1); */
    will-change: transform;
    transition: transform 0.5s ease;
}

.hardware__item:hover .hardware__featured-image img {
    transform: scale(1.1, 1.1);
}

@media only screen and (max-width: 540px) {
    .hardware__items {
        grid-template-columns: 1fr;
    }
}

/* .hardware__manufacturer .pill {
    font-size: 1rem;
    border-radius: 0.6rem;
    padding-inline: 0.6rem;
    padding-bottom: 1px;
} */

/* .hardware__promo .pill {
    font-size: 1rem;
    border-radius: 0.6rem;
    padding-inline: 0.6rem;
    padding-bottom: 1px;
} */

.hardware__category-wrapper {
    margin-block: 8rem;
    background: linear-gradient(180deg,
            #fff,
            var(--background-light) 77%,
            #fff 0);
    margin-inline: -1rem;
    /* Needed for bg offset */
    padding-inline: 1rem;
    /* Needed for bg offset */
}

.hardware__item {
    background-color: white;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
}

/* Section Hardware Detail */

.section--hardware-detail {
    padding-inline: 0;
}

.hardware-detail__hero {
    padding-top: clamp(8rem, 6.4rem + 8vw, 16rem);
    background: #f0f9ff;
    padding-bottom: 0;
}

.section--hardware-detail .hardware__title {
    font-size: var(--text-700);
    margin-bottom: 1rem;
}

.section--hardware-detail .hardware__subtitle {
    font-size: var(--text-600);
    margin-bottom: 1rem;
    line-height: 1.2;
}

.hardware__intro {
    font-size: var(--text-500);
    margin-bottom: 1.5rem;
    font-weight: 300;
}

.section--hardware-detail .hardware__featured-image img {
    background-color: transparent;
    object-fit: contain;
    max-height: 400px;
}

.hardware__features {
    grid-template-columns: repeat(auto-fit, minmax(min(125px, 100%), 1fr));
    gap: 1rem;
    display: grid;
    padding-bottom: 1.5rem;
    background-color: white;
}

.hardware__feature {
    background-color: var(--background-light);
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
    display: flex;
    font-size: var(--text-300);
    text-align: center;
    border-radius: 1rem;
    /* z-index: 1; */
}

.hardware__feature p {
    margin-bottom: 0;
}

.hardware__price {
    line-height: 1.2;
    margin-bottom: 1.5rem;
}

.hardware__price small {
    font-size: var(--text-300);
}

.hardware__price .price {
    margin-bottom: 0;
    font-weight: 900;
    font-size: var(--text-600);
}

.hardware-detail__header .container {
    padding-inline: 1rem;
}

.hardware-detail__hero .hardware__featured-image {
    width: min(100% - 2.25rem, 70rem);
    margin-inline: auto;
}

.hardware-detail__header-intro {
    background-color: transparent;
    padding-inline: 1.125rem;
}

.hardware-detail__header-image {
    background: linear-gradient(#f0f9ff, #f0f9ff 50%, #fff 0);
    padding-inline: 1.125rem;
}

.hardware-detail__header-specs {
    background-color: white;
    padding-inline: 1.125rem;
}

.hardware__icon img {
    width: 3rem;
}

/* Streamfield Tic Toc */

.streamfield__tictoc .container {
    display: grid;
    gap: clamp(2rem, 1.6rem + 2vw, 4rem);
    row-gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));
}

.streamfield__tictoc--image-left .streamfield__tictoc--image {
    order: 1;
}

.streamfield__tictoc--image-right .streamfield__tictoc--image {
    order: -1;
}

/* Icon Item */

.icon-item {
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 0.75rem;
    display: grid;
}

.icon-item__icon {
    width: 100%;
}

.icon-item__content {
    text-align: left;
    line-height: 1.2;
}

/* Featured Domain Listing */

.section--featured-domains {
    background-color: var(--background-light);
    text-align: center;
}

.section--featured-domains .container {
    max-width: 76rem;
}

/* Dense Listing */

.domain-listing__items {
    grid-gap: 0.75rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 2fr));
    display: grid;
    margin-bottom: 3rem;
}

.domain-listing__item {
    justify-content: space-between;
    gap: 1rem;
    display: flex;
    background: white;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
}

/* .domain__extension {
    font-weight: 900;
    display: flex;
    gap: 1rem;
} */

.domain__desc {
    font-weight: 600;
    opacity: 0.6;
    color: black;
}

.domain__price {
    font-weight: 900;
}

/* Featured Domains */

.domain-listing__items--featured {
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.domain-listing__items--featured .domain-listing__item {
    display: block;
}

.domain-listing__item {
    will-change: transform;
    background-color: #fff;

    transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
    box-shadow: 0 2px 5px -1px #32325d40, 0 1px 3px -1px #0000004d;
    text-align: left;
}

.domain-listing__item:hover {
    background-color: #d6f8ff;
    transform: translateY(-5px);
    cursor: pointer;
    box-shadow: 0 13px 27px -5px #32325d40, 0 8px 16px -8px #0000004d;
}

.domain-listing__items--featured .domain-listing__item {
    border-radius: 0.5rem;
    padding: 1rem;
}

.domain-listing__items--featured .domain__extension {
    font-size: var(--text-500);
    line-height: 1.2;
}

.domain-listing__items--featured .domain__desc {
    font-size: var(--text-300);
    line-height: 1.5;
}

.domain-listing__items--featured .domain__price {
    font-size: var(--text-600);
    font-weight: 900;
    line-height: 1.2;
}

@media only screen and (max-width: 600px) {
    .domain-listing__items .domain__desc {
        display: none;
    }

    .domain-listing__items--featured .domain__desc {
        display: block;
    }

    .domain-listing__items {
        grid-template-columns: repeat(auto-fit, minmax(130px, 2fr));
    }
}

/* Domain Search Results */

.section--domain-results {
    background-color: var(--background-light);
}

.section--domain-results .title {
    font-size: var(--text-500);
    margin-bottom: var(--spacing-500);
    font-weight: 900;
}

.result-table {
    background-color: #fff;
    border-radius: 1rem;
    padding-block: 1rem;
    padding-inline: 1rem;
}

.result-table__item {
    display: flex;
    gap: 2rem;
    row-gap: 0.5rem;
    column-gap: 2rem;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding-block: 1rem;
    flex-wrap: wrap;
}

.result-table__cell--left {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
}

.domain__name {
    font-weight: 900;
    font-size: var(--text-500);
}

.result-table__item .domain__name {
    font-size: var(--text-400);
    word-break: break-all;
}

.domain__extension {
    color: var(--primary-color);
    font-weight: 900;
}

.result-table__cell--name {
    flex-direction: column;
    display: flex;
    gap: 0;
    align-items: start;
}

.result-table__cell--name {
    gap: 0.5rem;
}

/* .result-table__cell--price {
    font-size: var(--text-400);
}
.result-table__cell--cta {
} */

@media only screen and (max-width: 600px) {
    .result-table__item {
        flex-direction: column;
    }

    .result-table__cell--left {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.domain__price {
    font-weight: 900;
    font-size: var(--text-500);
}

.domain__price--vat {
    font-weight: 600;
    font-size: var(--text-300);
    opacity: 0.7;
}

.preferred-domain {
    background-color: var(--background-light);
    border-left: 1rem solid #00a721;
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    display: flex;
}

.preferred-domain--warning {
    border-left: 1rem solid #ffa500;
}

.preferred-domain__options {
    align-items: center;
    gap: 0.5rem;
    display: flex;
}

.preferred-domain__cells {
    display: flex;
    flex-direction: column;
    /* gap: 0.5rem; */
}

@media only screen and (max-width: 600px) {

    .preferred-domain__options,
    .preferred-domain {
        flex-wrap: wrap;
    }
}

.domain-search__result {
    display: flex;
    color: #00a721;
    gap: 0.5rem;
    margin-block: 2rem;
    align-items: center;
    font-weight: 500;
}

.domain-search__result .icon {
    background-color: #ccedd2;
    padding: 0.3rem;
    border-radius: 50%;
}

.domain-search__result--warning .icon {
    background-color: transparent;
    border-radius: none;
    padding: 0;
}

.domain-search__result--warning,
.domain-search__result--warning .icon {
    color: #ffa500;
}

.domain-search__input-row {
    display: flex;
    gap: 1rem;
}

.domain-search__transfer-link {
    font-size: var(--text-300);
    margin-top: 1rem;
}

.domain-search__input-row input {
    flex-grow: 1;
    max-width: 40rem;
}

@media only screen and (max-width: 600px) {
    .domain-search__input-row {
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
    }

    .domain-search__transfer-link {
        margin-top: 0.5rem;
        display: block;
    }
}

/* Search Bar */

#search-form {
    margin-bottom: 0;
    align-items: center;
    display: flex;
    position: relative;
}

.search-content #search-bar {
    height: 1rem;
    width: 2.8em;
    padding: 1.25rem 1rem;
    border: 0;
    border-radius: 1rem;
    transition-property: background-color, border-color, width;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: hsla(0, 0%, 100%, 0);
    border-width: 1px;
    border-style: solid;
    color: hsla(0, 0%, 100%, 0);
    border-color: hsla(0, 0%, 100%, 0);
    font-size: 1rem;
}

.search-content #search-bar:focus {
    width: 20em;
    outline: 0;
    color: hsla(0, 0%, 20%, 1);
    background-color: hsla(0, 0%, 90%, 1);
    border-color: hsla(0, 0%, 90%, 1);
}

.search-content #search-bar:focus::-webkit-input-placeholder {
    opacity: 0.7;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.search-content #search-bar::-webkit-input-placeholder {
    opacity: 0;
    color: #535c68;
}

.search-content .icons-container {
    position: absolute;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

/* Search Icon */

.search-content .icons-container #search-icon {
    width: 1.5rem;
    height: 1.5rem;
}

@media only screen and (max-width: 414px) {
    .search-content #search-bar:focus {
        width: 100%;
    }
}

/* Section Backgrounds */

.section-background--default {
    background-color: white;
    isolation: isolate;
}

.section-background--lightgray {
    background-color: var(--background-light);
    isolation: isolate;
}

.section-background--half-lightgray {
    background: linear-gradient(180deg,
            #fff,
            var(--background-light) 77%,
            #fff 0);
    isolation: isolate;
}

.section-background--gradient-top_down-lightgray {
    background: linear-gradient(#f5f5f7 0%, #fff 40%);
}

.section-background--gradient-bottom_up-lightgray {
    background: linear-gradient(#fff 50%, #f5f5f7 100%);
}

/* 404 / 500 */
.section--error-page {
    padding-block-start: 1rem;
}

/* Search Results */

.search-results__title {
    color: #001f5c;
    font-size: var(--text-700);
    margin-bottom: var(--spacing-600);
}

.search-results__subtitle {
    color: #001f5c;
    font-size: var(--text-600);
    margin-bottom: var(--spacing-600);
    font-weight: 700;
    line-height: 1;
}

.section--search-results form {
    display: flex;
    gap: 1rem;
}

.section--search-results li {
    margin-bottom: var(--spacing-700);
}

.search-result__title {
    text-decoration: none;
    color: #001f5c;
    font-weight: 900;
    margin-bottom: 0.5rem;
    font-size: var(--text-500);
    display: inline-block;
}

.search-result__description {
    margin-bottom: 0.5rem;
    font-size: var(--text-400);
}

.search-result_url {
    text-decoration: none;
    color: var(--foreground);
    font-size: var(--text-300);
}

.pagination {
    display: flex;
    gap: 2rem;
}

.pagination__index {}

.pagination__navigation {
    display: flex;
    gap: 0.5rem;

    font-weight: 900;
}

.pagination__navigation a {
    text-decoration: none;
    color: #001f5c;
}

.pagination__navigation a:hover {
    text-decoration: underline;
}

/* Section Order PBX - Slides */

.section--pbx .order__product-title {
    font-weight: 400;
}

.section--pbx .swiper-slide {
    max-width: 300px;
}

.section--pbx .card-bullet-title {
    text-transform: unset;
}

/* Sales Section Form */

.section--has-form .sales-contact__title {
    font-size: var(--text-600);
    color: unset;
}

.section--has-form .sales-contact__subtitle {
    font-size: var(--text-450);
    color: unset;
}



/* Sales Form */

.section--has-form .container--sales-contact {
    grid-template-columns: repeat(auto-fit, minmax(min(290px, 100%), 1fr));
}

.sales-form {
    /* background-color: rebeccapurple; */
    grid-column: 1/-1;
}

.sales-form.wizard {
    grid-template-columns: 1fr;
    background-color: unset;
}

.sales-form.wizard .main__input-grid button {
    background-color: transparent;
    border: 2px solid;
    border-color: unset;
    border-radius: 2rem;
    gap: unset;
}

.sales-form.wizard .main__input-grid button:hover {
    background-color: #fff;
    box-shadow: 3px 3px #000;
}

.sales-form.wizard .choice-chips {
    justify-content: start;
}

.sales-form.wizard .choice-chips__label {
    font-size: var(--text-400);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* Section Order PBX - Slides */

.section--pbx .order__product-title {
    font-weight: 400;
}

.section--pbx .swiper-slide {
    max-width: 300px;
}

.section--pbx .card-bullet-title {
    text-transform: unset;
}

/* Section Order Fiber - Slides */

.section--fiber .swiper-slide {
    max-width: 300px;
}

.section--fiber .icon-list {
    margin-block: 1rem;
}

.section--fiber .quickcheck__card {
    background-color: white;
}

.section--fiber .quickcheck__card form {
    margin-bottom: 0;
}

.section--fiber .quickcheck__card {
    margin-bottom: 2rem;
}

.section--fiber .availability-form__footer {
    gap: 1rem;
    flex-wrap: wrap;
}

.section--fiber .availability-form__footer .button--small .icon {
    width: 1rem;
    height: 1rem;
}

.section--fiber .order__header {
    margin-bottom: 3rem;
}