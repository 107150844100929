/*------------------------------------*\
  #LISTS
\*------------------------------------*/

[class*="streamfield__"] ul li::marker {
    color: var(--primary-color);
}

/* Custom List Bullets */

[class*="streamfield__"] ul li {
    position: relative;
    line-height: 1.4;
    margin-bottom: 0.6rem;
    padding-left: 1.4rem;
}

[class*="streamfield__"] h3 {
    margin-bottom: 1.5rem;
    margin-top: 3rem;
}

[class*="streamfield__"] ul li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 4px;
    z-index: 2;
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
}

[class*="streamfield__"] ul li:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    width: 14px;
    height: 14px;
    background-color: #11ade5;
    border-radius: 50%;
}

/* Arrow Lists */

.list__features ul {
    padding-left: 2rem;
    list-style-type: none;
}

.list__features li {
    padding-left: 2rem;
    background-position: 0 0;
    background-size: 1.6rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' fill='rgba(50,152,219,1)'/%3E%3C/svg%3E");
}

/* Channel Listings */

:root {
    --channel-logo-listing-size: clamp(4rem, 3.2rem + 4vw, 8rem);
}

.channel__list {
    display: grid;
    grid-template-columns: repeat(auto-fill,
            minmax(var(--channel-logo-listing-size), 1fr));
    grid-gap: 1rem;
}

.channel__list>li>svg,
.channel__list>li>img {
    max-height: var(--channel-logo-listing-size);
    padding: clamp(0.5rem, 0.3rem + 1vw, 1.5rem);
}

.channel__list>li {
    min-width: var(--channel-logo-listing-size);
    height: var(--channel-logo-listing-size);
    background-color: #fff;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
}

.channel__specs {
    position: absolute;
    bottom: 0.4rem;
    left: 0.8rem;
    font-size: 12px;
    font-weight: bold;
    color: #B3B3B3;
}

.channel__package-label {
    position: absolute;
    top: -0.6rem;
    right: 0.8rem;
    background-color: #11ADE5;
    color: #fff;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 8px;
    letter-spacing: 1px;
}

/* Tools */

.tool__list {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.container--tools {
    width: min(100% - 2.25rem, 50rem);
    margin-inline: auto;
}

/* Comparison Spec Listing */

.comparison {
    margin-bottom: 4rem;
}

.comparison__row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    justify-items: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
}

.comparison__column {
    max-width: 640px;
}

.comparison__column ul {
    display: flex;
    row-gap: 0rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.comparison__product-image img {
    object-fit: contain;
    width: auto;
    height: 100%;
    max-height: 300px;
    aspect-ratio: 16 / 9;
}

.comparison__product-title {
    font-size: var(--text-500);
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

.comparison__product-title b {
    font-weight: 900;
}

.comparison__specs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.comparison__spec {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.comparison__icon {
    display: inline-block;
}

.comparison__label {
    font-size: var(--text-300);
}

.comparison__value {
    font-weight: 900;
    font-size: var(--text-500);
}

.comparison__product hr {
    width: 80%;
    display: flex;
    justify-self: center;
    margin-bottom: 0;
}