/* Default Form Styles */

form {
    margin-bottom: 3rem;
}

input,
textarea,
select {
    font-family: inherit;
    font-size: var(--text-400);
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border: 1px solid #ccc;
    border-radius: var(--default-radius);
    background-color: white;
}

label {
    font-size: var(--text-300);
    font-weight: 600;
    margin-bottom: 0.3rem;
}

input {
    transition: all 0.2s ease-in-out;
}

input:focus {
    background-color: #f5fafe;
    border-color: #0091f8;
    color: var(--primary-color);
    outline: none;
}

.form__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

/* Availability Form Styles */

.availability-form {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.availability-form__header {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.availability-form__footer {
    display: flex;
    align-self: center;
}

.availability-form__input-container {
    display: flex;
    flex-direction: column;
}

.availability-form label {
    font-size: var(--text-300);
}

/* .availability-form select,
.availability-form input {
    background: #fff;
    border-radius: var(--default-radius);
    border: 1px solid #ccc;
    transition: all 0.2s ease-in-out;
    padding-inline: 1rem;
    font-size: var(--text-400);
}

.availability-form select,
.availability-form input,
.availability-form__submit-button {
    height: 3rem;
} */

#feascheck_button {
    font-size: var(--text-400);
}

.availability-form input:focus,
.availability-form select:focus {
    background-color: #f5fafe;
    border-color: #0091f8;
    color: var(--primary-color);
    outline: none;
}

/* .availability-form .availability-form__submit-button {
    font-size: var(--text-400);
} */

/* Stretch Input Fields */

.availability-form__city,
.availability-form__street,
.availability-form__zipcode,
.availability-form__streetnr {
    flex: 1 0 auto;
}

.availability-form__input-container.form-submit {
    display: flex;
}

/* Style Custom Arrow For Selection Box */

select {
    appearance: none;
    padding-right: 2.3rem;
    background-position: calc(100% - 0.8rem) center !important;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-color: white;
}

/* To remove the arrow of select element in IE */
.select1::-ms-expand {
    display: none;
}

/* Weaken Disabled Form Fields */

.availability-form select:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

/* Check Availability Button Placement */

.order__subtitle-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--spacing-600);
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}

.order__subtitle-wrapper .order__subtitle {
    flex: 1;
    flex-basis: 540px;
}

.section--fiber .order__subtitle-wrapper {
    align-items: baseline;
}

.availability-form {
    max-height: 600px;
    opacity: 1;
    pointer-events: all;
    transition: max-height 0.25s ease, opacity 0.25s ease, transform 0.25s ease;
    transform: translateY(0);
}

.availability-form.hide {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: max-height 0.25s ease, opacity 0.25s ease, transform 0.25s ease;
    overflow: visible;
    transform: translateY(2rem);
    display: flex;
}

/* disable submit button styles */

.availability-form__submit-button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    /* border-color: inherit; */
    transform: none;
    box-shadow: none;
}

/* Zip Code Preifx + Placeholder Shift */

.availability-form__zipcode .prefix {
    font-weight: 900;
    position: absolute;
    transform: translate(1rem, 1px);
}

.availability-form__zipcode input {
    flex-grow: 1;
    padding-left: 2.5rem;
}

.prefix-wrapper {
    display: flex;
    align-items: center;
}

/* TV Package Toggle Section */

.order__tv-package {
    display: flex;
    max-width: 640px;
    width: 100%;
    gap: 1rem;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-bottom: 3rem;
}

.order__tv-package .icon-list__item .icon {
    color: black;
}

/* Loading Spinner */

.loading {
    color: transparent;
}

.loading:hover {
    color: transparent;
}

.loading:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.15);
}

.loading:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    animation: button-spin 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 40rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
    border-top-color: rgba(0, 0, 0, 0.5);
}

@keyframes button-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Prevent too many characters in feasibility button */

#feascheck_button_title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* Webmail Login */

.section--webmail-login button {
    font-size: var(--text-400);
}

/* Error Messages */

.error-msg {
    display: flex;
    background-color: hsla(0, 100%, 47%, 0.1);
    color: #ef0000;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    gap: 1rem;
}

.error-msg__icon {
    display: flex;
    min-width: 2rem;
    align-items: start;
}

.error-msg__icon svg {
    width: 100%;
}

.error-msg__content {
    text-align: left;
    flex-grow: 1;
    font-weight: 600;
    line-height: 1.25;
}

/* Basic Amount Stepper */

.amount-stepper {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.amount-stepper .icon {
    width: 2rem;
    height: 2rem;
    transition: color 250ms ease;
}

.amount-stepper__value {
    font-weight: 300;
    font-size: var(--text-200);
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.amount-stepper__value b {
    font-weight: 900;
    font-size: var(--text-500);
    transform: translateY(-2px);
}

.amount-stepper .icon-disabled {
    opacity: 0.25;
}

.amount-stepper .icon:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.amount-stepper .icon-disabled:hover {
    color: black;
    pointer-events: none;
}

/* Domain Quick Check */

.domain-quick-check__input-wrapper {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.domain-quick-check__input {
    flex-grow: 1;
}

/* Side-by-side toggle */

.toggle--dual-label {
    position: relative;
    left: -1rem;
}

.toggle--dual-label__label {
    box-sizing: border-box;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ic-Form-group.ic-Form-group--horizontal .toggle--dual-label__label {
    display: inline-flex;
    align-items: center;
}

.toggle--dual-label__input {
    opacity: 0;
}

.toggle--dual-label__input:checked~label .toggle--dual-label-switch:after {
    transform: translate3d(100%, 0, 0);
}

.toggle--dual-label__input:checked~label .toggle--dual-label__disabled-msg:before {
    content: attr(data-checked);
}

.toggle--dual-label__input[disabled]~label .toggle--dual-label__disabled-msg {
    display: block;
}

.toggle--dual-label__input[disabled]~label .toggle--dual-label-switch,
.toggle--dual-label__input[disabled]~label [class^=toggle--dual-label-option-] {
    display: none;
}

.toggle--dual-label-switch {
    transition: background 0.1s, border-color 0.1s;
    display: inline-block;
    position: relative;
    line-height: 1;
    display: flex;
    align-items: center;
    background-clip: padding-box;
}

.toggle--dual-label-switch::after {
    transition: all 0.1s ease-in-out;
    content: "";
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    transform: translate3d(0, 0, 0);
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.toggle--dual-label__disabled-msg {
    display: none;
}

.toggle--dual-label__disabled-msg:before {
    content: attr(data-unchecked);
    font-style: italic;
    opacity: 0.8;
}

[class^=toggle--dual-label-option-] {
    transition: opacity 0.2s;
    z-index: 1;
    text-transform: uppercase;
    speak: none;
}

.toggle--dual-label__screenreader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.toggle--dual-label .toggle--dual-label__input:checked~label .toggle--dual-label-option-LEFT {
    color: currentColor;
}

.toggle--dual-label .toggle--dual-label__input:checked~label .toggle--dual-label-option-RIGHT {
    color: #007aff;
}

.toggle--dual-label .toggle--dual-label-switch {
    width: 4rem;
    height: 2rem;
    background: #c3dbff;
    border: 2px solid #004eff;
    border-radius: 1rem;
}

.toggle--dual-label .toggle--dual-label-switch:after {
    background-color: white;
    width: 2.2rem;
    height: 2.2rem;
}

.toggle--dual-label .toggle--dual-label-option-LEFT {
    color: #007aff;
}

.toggle--dual-label .toggle--dual-label-option-RIGHT {
    color: currentColor;
}

.toggle--dual-label .toggle--dual-label__label {
    display: inline-flex;
    align-items: center;
    gap: .75rem;
}

.toggle--dual-label [class^=toggle--dual-label-option-] {
    flex: none;
}

.toggle--dual-label .toggle--dual-label__input:focus~label .toggle--dual-label-switch::after {
    box-shadow: inset 0 0 0 1px white, inset 0 0 0 3px #007aff, 0 3px 6px #007aff4d;
}