.choice-chips {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.choice-chips input[type="radio"] {
    display: none;
}

.choice-chips label {
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 32px;
    font-weight: 400;
    padding-block: 0.3rem 0.4rem;
    padding-inline: 0.8rem;
    display: flex;
    gap: 0.3rem;
    font-size: 0.875rem;
    cursor: pointer;
    background-color: white;
    color: #000;
    transition-property: color, border-color;
    transition-duration: 250ms;
    align-items: center;
}

.choice-chips input:checked+label {
    background-color: white;
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.choice-chips .icon {
    transition-duration: 100ms;
    transition-property: width, height;
    width: 0rem;
    height: 0rem;
}

.choice-chips input:checked+label .icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.2rem;
}

/* Disabled State */

.choice-chips input[type="radio"]:disabled+label {
    opacity: .4;
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
    border-color: hsl(0, 0%, 50%);
}

/* News Filter */

.news__filter-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
    justify-content: center;
}

.news__filter-item {
    border-style: solid;
    border-width: 2px;
    border-color: var(--background-light);
    background-color: var(--background-light);
    border-radius: 2rem;
    font-weight: 400;
    padding-block: 0.3rem 0.4rem;
    padding-inline: 0.8rem;
    display: flex;
    gap: 0.3rem;
    font-weight: 600;
    font-size: 0.875rem;
    transition-property: color, border-color;
    transition-duration: 250ms;
    align-items: center;
}

.section--news-index .news__filter-item {
    color: hsla(0, 0%, 0%, 0.85);
    text-decoration: none;
}

.news__filter-item .icon {
    display: none;
}

.selected .news__filter-item .icon {
    display: block;
}

.selected .news__filter-item {
    background-color: white;
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.news__filter-item:hover {
    color: var(--primary-color);
    background-color: #f2f7fa;
}

.news__filter .icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.2rem;
}

/* Sidebyside Filter Otions */

.sidebyside-options {
    display: flex;
    gap: 1rem 3rem;
    flex-wrap: wrap;
    justify-content: center;
}