/* Header */

.header--transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: visible;
}

/* Header Top Row */

.header__top {
    display: none;
    padding-inline: var(--default-inline-padding);
}

.divisions__link,
.quicklinks__link,
.desktop-menu__link,
.search__trigger,
.language-switcher__trigger {
    text-decoration: none;
}

.divisions__link,
.header__mobile-trigger a,
.quicklinks__link,
.search__trigger,
.language-switcher__trigger {
    color: currentColor;
}

.header__bottom {
    padding-inline: var(--default-inline-padding);
}

.container--header-bottom,
.container--header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 76rem;
}

.header__bottom--left {
    display: flex;
    gap: 4rem;
}

.header__bottom--right {
    display: flex;
    gap: 1rem;
    font-size: var(--text-300);
    align-items: center;
    /* z-index: 10; */
}

.header__desktop-menu {
    display: flex;
    gap: 1rem;
}

/* Language Switcher Dropdown */

.language-menu {
    opacity: 0;
    display: flex;
    visibility: hidden;
    transform: translateY(0.5rem);
    transition: visibility 0s 0.4s, opacity 0.3s ease-out,
        transform 0.25s ease-out;
    position: absolute;
    border: 1px solid #f3f3f3;
    border-radius: 0.5rem;
    padding: 0.8rem;
    background-color: white;
    margin-top: 0.5rem;
    flex-direction: column;
    gap: 0.5rem;
    box-shadow: var(--default-box-shadow);
    z-index: 3;
}

.language-menu.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0rem);
    transition-delay: 0s;
    display: flex;
}

.language-menu a {
    color: currentColor;
    text-decoration: none;
}

li[role="language-menu__item"] {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

li[role="language-menu__item"] svg {
    height: 1rem;
    width: 1rem;
    clip-path: circle(1rem at center);
}

/* Search Component */

.search__container {
    display: none;
}

.nav__divisions {
    display: flex;
    gap: 1rem;
}

/* Header Logo */

.icon__horizontal-logo {
    width: 13rem;
    height: 100%;
}

.icon__brandmark {
    width: 4rem;
    height: 100%;
}

/* Switch Logos on Mobile */

.search__label,
.header__desktop-menu,
.header__language-switcher,
.icon__horizontal-logo,
.language-switcher__label,
.quicklinks__label {
    display: none;
}

.nav__quicklinks {
    display: flex;
    gap: 1rem;
}

.quicklinks__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.search__trigger {
    display: flex;
    gap: 0.5rem;
}

.language-switcher__trigger {
    display: flex;
    gap: 0.5rem;
}

.container--header-top {
    padding: 1rem 0rem 0rem 0rem;
    font-size: var(--text-300);
}
.container--header-bottom {
    padding: 2.25rem 0rem 1rem 0rem;
}

/* Division Links */

.divisions__link {
    opacity: 0.6;
    transition-property: opacity, color;
}

.divisions__link:hover {
    opacity: 1;
    color: var(--primary-color);
}

.divisions__link--active {
    opacity: 1;
}

/* Desktop Menu Links */

.desktop-menu__link {
    color: #2e54a1;
    transition-property: color;
    font-weight: 600;
}

.desktop-menu__link--active {
    color: #14afe6;
}

.desktop-menu__link:hover {
    color: #14afe6;
}

.search__trigger,
.language-switcher__trigger,
.quicklinks__link {
    transition-property: color;
}
.search__trigger:hover,
.language-switcher__trigger:hover,
.quicklinks__link:hover,
.header__mobile-trigger:hover a {
    opacity: 1;
    color: var(--primary-color);
}

.header__mobile-trigger a {
    display: flex;
    align-items: center;
}

.header__desktop-menu {
    transform: translateY(-1px);
}

@media screen and (min-width: 600px) {
    .header__top {
        display: block;
    }
    .header__desktop-menu {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        row-gap: 0;
        line-height: 1rem;
    }

    .header__language-switcher {
        display: inline-block;
    }

    .header__mobile-trigger {
        display: none;
    }
}

@media screen and (min-width: 821px) {
    .search__label,
    .language-switcher__label,
    .quicklinks__label {
        display: block;
    }
}

@media screen and (min-width: 960px) {
    .icon__horizontal-logo {
        display: block;
    }
    .icon__brandmark {
        display: none;
    }
}
