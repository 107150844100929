/*

ToDo
Card Grid Layout
https://www.youtube.com/watch?v=rg7Fvvl3taU&t=6s


*/

.section--tictoc {
    padding: var(--section-padding);
}

.tictoc {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.tictoc__image {
    aspect-ratio: 4/3;
    height: inherit;
}

.tictoc--overlap .tictoc__image {
    aspect-ratio: 4/3;
}

/* Tic Toc Variants */

.tictoc__media-block {
    grid-column: 1/-1;
    grid-row: 1/2;
    border-radius: 1rem;
    overflow: hidden;
}

.tictoc__content {
    grid-column: 1/-1;
    grid-row: 2/3;
    top: -3rem;
    margin-inline: 1rem;
    position: relative;
}

.tictoc .button-group {
    justify-content: left;
}

.tictoc--overlap .button-group {
    justify-content: center;
}

.tictoc__content {
    box-shadow: 0 10px 99px 0 rgb(0 84 203 / 8%);
    padding: 1.5rem;
    border-radius: 1rem;
}

/* slightly transparent fallback */
.tictoc__content {
    background-color: rgba(255, 255, 255, 1);
}

.tictoc__title--category {
    font-size: var(--text-400);
    margin-bottom: var(--spacing-400);
    color: var(--primary-color);
    font-weight: 700;
}

.tictoc__title {
    font-size: var(--text-650);
    margin-bottom: var(--spacing-400);
    font-weight: 900;
}

.button-group {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.tictoc__container {
    max-width: 74rem;
}

.icon-list {
    list-style: none;
    padding-left: 0;
    justify-content: left;
}

.icon-list__item {
    padding-left: 2rem;
    position: relative;
    margin-bottom: 0.5rem;
    line-height: 1.3;
    text-align: left;
}

.icon-list__label {
    margin-block: 0.5rem 1rem;
}

.icon-list__label p {
    margin-block-end: 1.125rem;
}

.icon-list__item .icon {
    color: var(--primary-color);
    position: absolute;
    left: 0;
}

.tictoc__content-header {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}

.tictoc__title--category {
    margin-bottom: 0;
}

.tictoc__content-header {
    margin-bottom: 1.5rem;
}

.tictoc .pill {
    background-color: orange;
    color: white;
    /* padding-inline: 0.7rem; */
    font-size: var(--text-300);
    font-weight: 500;
    /* padding-block: clamp(0.1rem, 0.082rem + 0.09vw, 0.19rem); */
}

.tictoc--overlap .tictoc__content,
.tictoc--overlap .tictoc__media-block {
    border-radius: 1rem;
    overflow: auto;
}

.tictoc__content-footer .cta-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

@media only screen and (min-width: 640px) {
    .tictoc__content {
        grid-column: 1/-1;
        grid-row: 2/3;
        top: 0;
        margin-inline: 0;
        position: inherit;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tictoc__media-block {
        grid-row: 1/2;
        align-self: center;
        height: 100%;
    }

    .tictoc__content {
        grid-row: 1/2;
        align-self: center;
        height: 100%;
        border-radius: 0rem 1rem 1rem 0rem;
    }

    /* Default */

    .tictoc--has-image-left .tictoc__content {
        grid-column: 7/-1;
        border-radius: 0rem 1rem 1rem 0rem;
    }

    .tictoc--has-image-left .tictoc__media-block {
        grid-column: 1/7;
        border-radius: 1rem 0rem 0rem 1rem;
    }

    .tictoc--has-image-right .tictoc__content {
        grid-column: 1/7;
        border-radius: 1rem 0rem 0rem 1rem;
    }

    .tictoc--has-image-right .tictoc__media-block {
        grid-column: 7/-1;
        border-radius: 0rem 1rem 1rem 0rem;
    }

    /* Overlap */

    .tictoc--overlap.tictoc--has-image-left .tictoc__content {
        grid-column: 7/-1;
        height: auto;
    }

    .tictoc--overlap.tictoc--has-image-left .tictoc__media-block {
        grid-column: 1/8;
        height: auto;
    }

    .tictoc--overlap.tictoc--has-image-right .tictoc__content {
        grid-column: 1/7;
        height: auto;
    }

    .tictoc--overlap.tictoc--has-image-right .tictoc__media-block {
        grid-column: 6/-1;
        height: auto;
    }
}

@media only screen and (min-width: 960px) {
    .tictoc__image {
        /* aspect-ratio: 4/3; */
        height: inherit;
    }

    .tictoc__content {
        padding: 2.5rem;
    }

    .tictoc--overlap.tictoc--has-image-left .tictoc__content {
        grid-column: 8/-1;
        border-radius: 1rem;
    }

    .tictoc--overlap.tictoc--has-image-left .tictoc__media-block {
        grid-column: 1/9;
        border-radius: 1rem;
    }

    .tictoc--overlap.tictoc--has-image-right .tictoc__content {
        grid-column: 1/6;
        border-radius: 1rem;
    }

    .tictoc--overlap.tictoc--has-image-right .tictoc__media-block {
        grid-column: 5/-1;
        border-radius: 1rem;
    }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .tictoc--overlap .tictoc__content {
        background-color: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(2em);
        backdrop-filter: blur(2em);
    }
}

.price-grid--inline>* {
    margin-bottom: 0;
}

.tictoc .price-grid--inline {
    margin-bottom: 1.5rem;
}

/* mediablock overlay */

.media-block--color-overlay {
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
}

.media-block--color-overlay img {
    position: inherit;
    z-index: -2;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.media-block--color-overlay::before,
.media-block--color-overlay::after {
    content: "";
    position: absolute;
    inset: 0;
}

.media-block--color-overlay::before {
    background-color: var(--primary-color);
    z-index: -1;
    mix-blend-mode: hard-light;
    overflow: hidden;
}
