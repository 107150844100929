@import url("https://use.typekit.net/rtp5ofz.css");

:root {
    --primary-color: var(--azure-radiance-700);
    --primary-color-50: var(--azure-radiance-50);
    --primary-color-100: var(--azure-radiance-100);
    --primary-color-200: var(--azure-radiance-200);
    --primary-color-300: var(--azure-radiance-300);
    --primary-color-400: var(--azure-radiance-400);
    --primary-color-500: var(--azure-radiance-500);
    --primary-color-600: var(--azure-radiance-600);
    --primary-color-700: var(--azure-radiance-700);
    --primary-color-800: var(--azure-radiance-800);
    --primary-color-900: var(--azure-radiance-900);

    --secondary-color: var(--web-orange-500);
    --secondary-color-50: var(--web-orange-50);
    --secondary-color-100: var(--web-orange-100);
    --secondary-color-200: var(--web-orange-200);
    --secondary-color-300: var(--web-orange-300);
    --secondary-color-400: var(--web-orange-400);
    --secondary-color-500: var(--web-orange-500);
    --secondary-color-600: var(--web-orange-600);
    --secondary-color-700: var(--web-orange-700);
    --secondary-color-800: var(--web-orange-800);
    --secondary-color-900: var(--web-orange-900);

    --azure-radiance-50: hsl(190, 100%, 96%);
    --azure-radiance-100: hsl(193, 100%, 92%);
    --azure-radiance-200: hsl(191, 100%, 85%);
    --azure-radiance-300: hsl(189, 100%, 76%);
    --azure-radiance-400: hsl(191, 100%, 64%);
    --azure-radiance-500: hsl(196, 100%, 56%);
    --azure-radiance-600: hsl(201, 100%, 51%);
    --azure-radiance-700: hsl(205, 100%, 50%);
    --azure-radiance-800: hsl(207, 92%, 40%);
    --azure-radiance-900: hsl(205, 85%, 33%);

    --web-orange-50: hsl(51, 100%, 96%);
    --web-orange-100: hsl(50, 100%, 89%);
    --web-orange-200: hsl(51, 100%, 76%);
    --web-orange-300: hsl(48, 100%, 64%);
    --web-orange-400: hsl(46, 100%, 55%);
    --web-orange-500: hsl(39, 100%, 50%);
    --web-orange-600: hsl(33, 100%, 44%);
    --web-orange-700: hsl(27, 98%, 37%);
    --web-orange-800: hsl(24, 90%, 31%);
    --web-orange-900: hsl(23, 84%, 26%);

    --gradient-darblue-blue: linear-gradient(-269deg, #0c095e, #1045db);

    --background: hsl(0, 0%, 100%, 1);
    --background-light: hsl(240, 11%, 96%, 1);
    /* --background-light: hsl(195, 25%, 98%, 1); */

    --foreground: hsl(0, 0%, 32%, 1);

    --MainFont: ibm-plex-sans, Roboto, -apple-system, BlinkMacSystemFont,
        Segoe UI, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;

    --text-800: clamp(4rem, 3.9rem + 0.5vw, 4.5rem); /* 64-72px*/
    --text-700: clamp(2.625rem, 2.55rem + 0.375vw, 3rem); /* 42-48px */
    --text-650: clamp(2.25rem, 2.2rem + 0.25vw, 2.5rem); /* 36-40px */
    --text-600: clamp(1.75rem, 1.7rem + 0.25vw, 2rem); /* 28-32px */
    --text-500: clamp(1.3125rem, 1.275rem + 0.1875vw, 1.5rem); /* 21-24px */
    --text-450: clamp(1.125rem, 1.0875rem + 0.1875vw, 1.3125rem); /* 18-21px */
    --text-400: clamp(1rem, 0.975rem + 0.125vw, 1.125rem); /* 16-18px */
    --text-300: clamp(0.875rem, 0.85rem + 0.125vw, 1rem); /* 14-16px */
    --text-200: clamp(0.75rem, 0.725rem + 0.125vw, 0.875rem); /* 12-14px */

    --default-line-height: 1.5;

    --default-animation-duration: 150ms;

    --default-radius: 8px;
    --default-margin: 1em;
    --default-inline-padding: clamp(1.125rem, 0.5rem + 3.125vw, 3rem);

    --icon-color-primary: inherit;
    --icon-color-secondary: currentColor;

    --default-box-shadow: 0 1px 1px hsl(0deg 0% 0% / 8%),
        0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%),
        0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%);

    --spacing-800: var(--text-800); /* 64-72px */
    --spacing-700: var(--text-700); /* 42-48px */
    --spacing-600: var(--text-600); /* 28-32px */
    --spacing-500: var(--text-500); /* 21-24px */
    --spacing-400: var(--text-400); /* 16-18px */
    --spacing-300: var(--text-300); /* 14-16px */

    --section-padding-block: clamp(4rem, 3.2rem + 4vw, 8rem);
    --section-padding-inline: var(--default-inline-padding);

    --section-padding: var(--section-padding-block)
        var(--section-padding-inline); /* 128-64px and 20px */

    --hide-on-mobile-breakpoint: 600px;

    --primary-text-decoration-color: var(--primary-color-300);
    --primary-link-color: var(--primary-color);
}
