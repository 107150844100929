/*------------------------------------*\
  #IMAGES
\*------------------------------------*/

img {
    object-fit: cover;
}

svg:not(:root) {
    overflow: hidden;
}

.image {
    margin-bottom: 3rem;
}

.icon {
    display: inline-block;
    color: var(--icon-color-primary);
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1;
    flex-shrink: 0;
    max-width: initial;
}

.icon use {
    --icon-stroke-linecap-butt: butt;
    stroke-miterlimit: 10;
    stroke-linecap: square;
    stroke-linejoin: miter;
    fill: var(--icon-color-secondary);
    stroke: var(--icon-color-secondary);
}

.icon--small {
    width: 1rem;
    height: 1rem;
}

.icon--big,
.icon--big svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-bottom: 0.5rem;
}

.icon--huge {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1.625rem;
}

.icon.promo-cards__icon {
    width: 6rem;
    height: 6rem;
    display: inline-flex;
}
