summary {
    margin-top: -1rem;
    margin-bottom: 0px;
    font-weight: bold;
    display: block;
    position: relative;
    cursor: pointer;
    padding-block: 1rem;
    padding-inline: 0 1.5rem;
}

summary:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.9393 39.3107C15.3536 38.7249 15.3536 37.7751 15.9393 37.1893L29.3787 23.75L15.9393 10.3107C15.3536 9.72487 15.3536 8.77513 15.9393 8.18934C16.5251 7.60355 17.4749 7.60355 18.0607 8.18934L32.5607 22.6893C33.1464 23.2751 33.1464 24.2249 32.5607 24.8107L18.0607 39.3107C17.4749 39.8964 16.5251 39.8964 15.9393 39.3107Z' fill='%23212121'/%3E%3C/svg%3E%0A");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 1rem;
    right: 0rem;
    transform: rotate(90deg);
    transition: 0.33s transform ease;
}

details {
    overflow: hidden;
    padding: 1rem 1rem 0;
    border-bottom: 1px solid #e9e9eb;
    padding-left: 0;
    padding-right: 0;
}

details:last-of-type {
    margin-bottom: 2rem;
}

details[open] {
    padding: 1rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
}

details[open] > summary:before {
    transform: rotate(270deg);
}

details summary::-webkit-details-marker {
    display: none;
}

details > ul {
    padding-bottom: 1rem;
    margin-bottom: 0;
}

.faq__subtitle {
    font-size: var(--text-600);
    margin-bottom: clamp(1.3125rem, 1.275rem + 0.1875vw, 1.5rem);
}

.annotation {
    text-align: center;
    font-size: var(--text-300);
}

.accordion__content p:last-child,
.accordion__content ul:last-child {
    margin-bottom: 0rem;
    padding-bottom: 1rem;
}

details[open] > .accordion__content {
    background: #f5f5f7;
    border-left: 2px solid var(--primary-color);
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
}

.accordion__content ul {
    padding-left: 2rem;
    list-style-type: disc;
}
