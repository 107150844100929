/*------------------------------------*\
  Menu Settings
\*------------------------------------*/

/* .nav-item,
#fullMenu-close-btn {
  cursor: pointer;
} */

.fullscreen-menu--private-theme {
    --fullscreenMenu-Background: hsla(220, 56%, 41%, 1);
    --fullscreenMenu-BackgroundTo: hsla(196, 84%, 49%, 1);
    --fullscreenMenu-SubtitleColor: hsla(0, 0%, 100%, 0.85);
    --fullscreenMenu-TextColor: hsla(0, 0%, 100%, 0.6);
    --fullscreenMenu-LinkColor: hsla(0, 0%, 100%, 1);
    --fullscreenMenu-ScrollbarTrack: hsla(0, 0%, 100%, 0);
    --fullscreenMenu-ScrollbarThumb: hsla(0, 0%, 100%, 0.4);
    --fullscreenMenu-ScrollbarThumbHover: hsla(0, 0%, 100%, 1);
}

.fullscreen-menu--business-theme {
    --fullscreenMenu-Background: hsla(220, 100%, 10%, 1);
    --fullscreenMenu-BackgroundTo: hsla(220, 100%, 10%, 1);
    --fullscreenMenu-SubtitleColor: hsla(183, 83%, 93%, 1);
    --fullscreenMenu-TextColor: hsla(183, 83%, 93%, 0.6);
    --fullscreenMenu-LinkColor: hsla(183, 83%, 93%, 1);
    --fullscreenMenu-ScrollbarTrack: hsla(183, 83%, 93%, 0);
    --fullscreenMenu-ScrollbarThumb: hsla(183, 83%, 93%, 0.4);
    --fullscreenMenu-ScrollbarThumbHover: hsla(183, 83%, 93%, 0.5);
}

.fullscreen-menu--hosting-theme {
    --fullscreenMenu-Background: hsla(220, 100%, 18%, 1);
    --fullscreenMenu-BackgroundTo: hsla(220, 100%, 18%, 1);
    --fullscreenMenu-SubtitleColor: hsla(183, 83%, 73%, 0.85);
    --fullscreenMenu-TextColor: hsla(183, 83%, 73%, 0.6);
    --fullscreenMenu-LinkColor: hsla(183, 83%, 73%, 1);
    --fullscreenMenu-ScrollbarTrack: hsla(183, 83%, 73%, 0);
    --fullscreenMenu-ScrollbarThumb: hsla(183, 83%, 73%, 0.4);
    --fullscreenMenu-ScrollbarThumbHover: hsla(183, 83%, 73%, 0.5);
}

.fullscreen-menu--company-theme {
    --fullscreenMenu-Background: hsla(0deg, 0%, 100%, 1);
    --fullscreenMenu-BackgroundTo: hsla(0deg, 0%, 100%, 1);
    --fullscreenMenu-SubtitleColor: hsla(220, 100%, 18%, 0.85);
    --fullscreenMenu-TextColor: hsla(220, 100%, 18%, 0.6);
    --fullscreenMenu-LinkColor: hsla(220, 100%, 18%, 1);
    --fullscreenMenu-ScrollbarTrack: hsla(220, 100%, 18%, 0);
    --fullscreenMenu-ScrollbarThumb: hsla(220, 100%, 18%, 0.4);
    --fullscreenMenu-ScrollbarThumbHover: hsla(220, 100%, 18%, 0.5);
}

.fullscreen-menu--tools-theme {
    --fullscreenMenu-Background: hsla(0deg, 0%, 94%, 1);
    --fullscreenMenu-BackgroundTo: hsla(0deg, 0%, 94%, 1);
    --fullscreenMenu-SubtitleColor: hsla(220, 100%, 18%, 0.85);
    --fullscreenMenu-TextColor: hsla(220, 100%, 18%, 0.6);
    --fullscreenMenu-LinkColor: hsla(220, 100%, 18%, 1);
    --fullscreenMenu-ScrollbarTrack: hsla(220, 100%, 18%, 0);
    --fullscreenMenu-ScrollbarThumb: hsla(220, 100%, 18%, 0.4);
    --fullscreenMenu-ScrollbarThumbHover: hsla(220, 100%, 18%, 0.5);
}

.fullscreen-menu {
    overflow: auto;
    background-image: linear-gradient(
        90deg,
        var(--fullscreenMenu-Background) 0%,
        var(--fullscreenMenu-BackgroundTo) 100%
    );
    height: 100vh;
    width: 100vw;
    color: var(--fullscreenMenu-TextColor);
    /* backdrop-filter: blur(3rem); */
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    content-visibility: auto;
    position: fixed;
    inset: 0px;
    padding-inline: var(--default-inline-padding);
    overflow: auto;
    transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out,
        color 250ms ease-in-out, background-color 250ms ease-in-out;
}

.fullscreen-menu--open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

/* Fullscreen Menu - Header */

/* Logo */

.fullscreen-menu__logo {
    width: 13rem;
    height: 3rem;
    color: var(--fullscreenMenu-LinkColor);
    transition: color 250ms ease-in-out;
}

/* Close Button */

.fullscreen-menu__close-button {
    transform: rotate(0deg);
    transition: transform 250ms;
    cursor: pointer;
    color: var(--fullscreenMenu-LinkColor);
    opacity: 0.6;
}

.fullscreen-menu__close-button:hover {
    transform: rotate(90deg);
    opacity: 1;
}

/* Link Hover States */

.fullscreen-menu a {
    text-decoration: none;
    transition-property: opacity, transform;
    color: var(--fullscreenMenu-LinkColor);
}

.fullscreen-menu__language-icon {
    color: var(--fullscreenMenu-LinkColor);
}

.quicklinks__link--fullscreen-menu:hover {
    color: var(--fullscreenMenu-LinkColor);
    filter: brightness(1);
}

.fullscreen-menu a[aria-selected="false"]:hover {
    opacity: 1;
}

.fullscreen-menu__discovery-link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.fullscreen-menu__discovery-link-icon {
    width: 1rem;
    height: 1rem;
}

.fullscreen-menu__container {
    max-width: 77rem;
    margin: 0 auto;
    height: 92vh;
    display: flex;
    flex-direction: column;
}

.fullscreen-menu__header {
    display: flex;
    justify-content: space-between;
    margin-top: clamp(1rem, -0.6875rem + 8.4375vw, 4.375rem);
    align-items: center;
    min-height: 3rem;
}

.fullscreen-menu__body {
    padding-top: clamp(2rem, 1.6rem + 2vw, 4rem);
    padding-bottom: 1rem;
    flex-grow: 1;
}

.fullscreen-menu__footer {
    background-color: transparent;
}

.fullscreen-menu ul {
    list-style: none;
    padding: 0;
}

.fullscreen-menu .menu__divisions ul {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
}

.fullscreen-menu .menu__categories ul {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
}

.fullscreen-menu .menu__subcategories {
    display: flex;
}

.fullscreen-menu .menu__list {
    display: flex;
    gap: 2rem;
}

.fullscreen-menu .menu--content ul:not(.active) {
    display: none;
}

.menu__discover .menu__discover--item.active {
    display: flex;
}

.menu__discover .menu__discover--item {
    display: none;
}

.fullscreen-menu .active {
    font-weight: bold;
    text-decoration: underline;
}

.fullscreen-menu [data-tabs] {
    display: flex;
    gap: 1rem;
    padding-top: 3rem;
}

.fullscreen-menu [data-panes] [data-tabs] {
    padding-top: 0rem;
    font-size: var(--text-700);
    font-weight: 300;
    gap: 2rem;
    line-height: 1.1;
    padding-bottom: clamp(2rem, 1.6rem + 2vw, 4rem);
}

.fullscreen-menu a[aria-selected="false"] {
    opacity: 0.4;
}

.fullscreen-menu a[aria-selected="true"] {
    opacity: 1;
    /* white-space: nowrap; */
}

.fullscreen-menu [data-tabs] {
    padding-top: 0;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;
}

.fullscreen-menu__tabs-lvl-2 {
    justify-items: right;
}

.fullscreen-menu [data-panes] {
    /* padding-top: 2.5rem; */
}

.fullscreen-menu [data-dicovery] {
    padding-bottom: 3.5rem;
    padding-top: 0;
}

.fullscreen-pane__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 3rem;
    column-gap: 3.5rem;
    flex-wrap: wrap;
}

.fullscreen-pane__title {
    font-size: var(--text-700);
    line-height: 1;
    font-weight: 500;
    margin-bottom: 2rem;
}

.fullscreen-pane__subtitle {
    font-size: var(--text-500);
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--fullscreenMenu-SubtitleColor);
}

.fullscreen-pane__description {
    margin-bottom: 2rem;
    color: var(--fullscreenMenu-TextColor);
}

.fullscreen-menu [data-dicovery] a {
    display: block;
}

/* Buttons */

.fullscreen-pane__button {
    border-radius: 2rem;
    border: 2px solid;
    padding: 0.4rem 1.2rem 0.5rem;
    cursor: pointer;
    /* display: inline-block; */
    background-color: transparent;
    color: var(--fullscreenMenu-LinkColor);
}

.fullscreen-pane__button:hover {
    background-color: var(--fullscreenMenu-LinkColor);
    color: var(--fullscreenMenu-Background);
    border-color: var(--fullscreenMenu-LinkColor);
    transition: all 250ms;
    box-shadow: none;
    transform: none;
}

@media only screen and (max-width: 640px) {
    .fullscreen-menu [data-tabs] {
        font-size: var(--text-300);
    }

    .fullscreen-pane__subtitle {
        display: none;
    }

    .fullscreen-pane__description {
        display: none;
    }

    .fullscreen-pane__button {
        display: none;
    }

    .fullscreen-menu [role="tabpanel"]:not([hidden]) {
        /* display: grid; */
        /* grid-template-columns: 1fr 1.5fr; */
        /* gap: 1rem; */
    }

    .fullscreen-menu__discovery {
        grid-column: 1/3;
    }

    .fullscreen-menu [role="tabpanel"]:not([hidden]) [data-tabs] {
        /* display: flex; */
        /* flex-direction: column; */
        gap: 1rem;
        font-size: var(--text-400);
        row-gap: 0.5rem;
        /* margin-top: 0.2rem; */
    }

    .fullscreen-menu [data-panes] [data-tabs] {
        font-size: var(--text-400);
        margin-bottom: 0;
        padding-bottom: 1.5rem;
    }

    .fullscreen-pane__grid {
        gap: 1.125rem;
        grid-template-columns: auto;
    }

    .fullscreen-pane__title {
        margin-bottom: 0;
        font-size: var(--text-600);
    }
}

/* Menu Footer */

.fullscreen-menu__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;
    opacity: 1;
    color: var(--fullscreenMenu-LinkColor);
    padding-bottom: clamp(1.25rem, 0.7rem + 2.75vw, 4rem);
}

.fullscreen-menu__footer a:hover {
    opacity: 1;
    color: inherit;
}

/* Fullscreen Language Switcher */

.fullscreen-menu__languageSwitcher {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.fullscreen-menu__languages {
    display: flex;
    gap: 1rem;
}

.fullscreen-menu__language {
    opacity: 0.4;
}

.fullscreen-menu__language--active {
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .fullscreen-menu__language {
        text-overflow: inherit;
        max-width: 2ch;
        text-transform: uppercase;
        overflow: hidden;
        font-family: monospace;
    }
}

.fullscreen-menu__discovery {
    display: inline-block;
    margin-block: 2rem 3.5rem;
}

.fullscreen-menu__discovery-link svg {
    transform: translateX(0rem);
    transition: ease 250ms;
}

.fullscreen-menu__discovery-link:hover svg {
    transform: translateX(0.5rem);
}

/* Full Screen Quicklink Hover Styles */

.quicklinks__link:hover


/* Full Screen Menu Image Block */

.fullscreen-pane__image-block {
    margin-bottom: 1rem;
}

.fullscreen-pane__image-link {
    transform: translateY(0rem);
    transition: ease 250ms;
    display: block;
}

.fullscreen-pane__image-link:hover {
    transform: translateY(-0.25rem);
}

.fullscreen-pane__image {
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .fullscreen-pane__image-block {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
}

/* Link Outlines on Focus */

.fullscreen-menu a:focus-visible {
    outline: 1px dashed var(--fullscreenMenu-LinkColor);
}
