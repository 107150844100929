[data-tabs],
[data-panes] {
    /* --color: #000; */
}

[data-tabs] {
    /* background: var(--color); */
    /* border: 2px solid var(--color); */
    border-bottom: none;
    /* overflow-x: auto; */
    display: flex;
}
[data-tabs] > [role="tab"] {
    /* all: unset; */
    /* padding: 0.75rem 1.5rem; */
    /* user-select: none; */
    /* font-weight: 600; */
    /* color: #fff; */
    /* border: 1px solid transparent; */
    outline: none;
}
[data-tabs] > [role="tab"]:hover {
    /* background: rgba(255, 255, 255, 0.2); */
}
[data-tabs] > [role="tab"]:active {
    /* background: rgba(255, 255, 255, 0.3); */
}
[data-tabs] > [role="tab"]:focus {
    /* border: 1px dotted rgba(255, 255, 255, 0.5); */
}
[data-tabs] > [role="tab"][aria-selected="true"] {
    /* background: #fff; */
    /* color: #000; */
}
[data-tabs] > [role="tab"][aria-selected="true"]:focus {
    /* border: 1px dotted rgba(0, 0, 0, 0.5); */
}

[data-panes] {
    /* border: 2px solid var(--color); */
    /* border-top: none; */
}
[data-panes] > * {
    /* background: #fff; */
    /* padding: 2rem; */
    /* font-weight: 600; */
    outline: none;
    /* border: 1px solid transparent; */
}
[data-panes] > *:focus {
    /* border: 1px dotted rgba(0, 0, 0, 0.5); */
}
@media screen and (max-width: 460px) {
    [data-panes] > * {
        /* padding: 1rem; */
    }
}
