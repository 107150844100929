/*------------------------------------*\
  #LINKS
\*------------------------------------*/

/**
 * Default styles for simple hyperlinks.
 */

a {
  color: var(--primary-link-color);
  text-decoration-color: var(--primary-text-decoration-color);
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: text-decoration-color;
  transition-delay: 0s;
}

a:hover {
  text-decoration-color: var(--primary-link-color);
}

/* Focus Styles */

/* focus styles can be hidden for click interactions but be visible for keyboards. */

a:focus {
  color: inherit;
}

:focus {
  /* outline: 1px dashed var(--primary-color); */
  /* outline-offset: 10px; */
}

[data-whatintent="mouse"] :focus {
  outline: none;
}

/* Custom Hover Animation */

/* Entering Left, Leaving Right - Fullheight */

.multiline-hover {
  background: linear-gradient(0deg, hsl(210, 100%, 92%), hsl(210, 100%, 92%))
    no-repeat right bottom / 0 var(--bg-h);
  transition: background-size 350ms;
  --bg-h: 100%;
  display: inline;
}
.multiline-hover:where(:hover, :focus-visible) {
  background-size: 100% var(--bg-h);
  background-position-x: left;
}

/* Entering Left, Leaving Right - Underline */

.multiline-hover--underline {
  padding-bottom: 2px;
  --bg-h: 2px;
}
