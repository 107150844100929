/* Annotations */

/* .annotation {
  margin: 0;
  border-color: #42b983;
  border-left-width: .5rem;
  border-left-style: solid;
  background: var(--light-backgroundcolor);
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: var(--default-radius);
}

.annotation-info {
  background-color: #f3f5f7;
  border-color: #42b983;
}

.annotation-warning {
  background-color: rgba(255, 229, 100, 0.3);
  border-color: #e7c000;
  color: #6b5900;
}

.annotation-danger {
  background-color: #ffe6e6;
  border-color: #c00;
  color: #4d0000;
}

.annotation-title {
  font-weight: 600;
}

.annotation-danger .annotation-title {
  color: #900;
}

.annotation-warning .annotation-title {
  color: #b29400;
} */

/* Cookie Banner */

.cookie-banner {
  background-color: var(--primary-color-50);
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  row-gap: .5rem;
  column-gap: 1rem;
  padding: .5rem;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  border-radius: 1rem;
  margin: 1rem;
  z-index: 9999;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}