/* Shared Styles */

.section--speed-comparison strong {
    font-weight: 900;
}

.section--speed-comparison .title {
    margin-bottom: clamp(1rem, 0.6rem + 2vw, 3rem);
    max-width: 25ch;
    margin-inline: auto;
}

.speed-comparison__item-title {
    margin-bottom: 0;
    font-size: var(--text-600);
}

.section--speed-comparison p {
    margin-bottom: 0;
}

.counter__time {
    font-size: var(--text-700);
}

.speed-comparison__counter {
    margin-bottom: clamp(1rem, 0.6rem + 2vw, 3rem);
}

.speed-comparison__card {
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.speed-comparison__item {
    margin-bottom: 1rem;
}

.section--speed-comparison {
    text-align: center;
}

.achievement__items {
    display: flex;
    justify-content: center;
    gap: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
    padding-block: 1rem;
    align-items: center;
}

.achievement__title {
    font-size: var(--text-500);
}

.achievement__subtitle {
    font-size: var(--text-300);
}

.speed-comparison__total-volume {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

/* Achievemnt Badge */

.achievement__item img {
    width: clamp(2rem, 1.7rem + 1.5vw, 3.5rem);
    aspect-ratio: 1/1;
    border-radius: 50%;
    filter: blur(0rem);
}

.achievement__recent img {
    width: clamp(3rem, 2.2rem + 4vw, 7rem);
}

.achievement__upcoming img {
    opacity: 0.2;
    filter: blur(0.25rem);
}

.achievement__counter {
    background-color: var(--background-light);
    font-weight: 900;
    font-size: var(--text-300);
    width: clamp(2rem, 1.7rem + 1.5vw, 3.5rem);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Selected Card */

.speed-comparison__item--active {
    margin-bottom: 2rem;
}

/* Compact Cards */

.speed-comparison__item--active .achievement__inner-title {
    display: none;
}

:not(.speed-comparison__item--active)
    > .speed-comparison__card
    > .achievement__desc {
    display: none;
}

:not(.speed-comparison__item--active)
    > .speed-comparison__card
    > .speed-comparison__item-title {
    display: none;
}

:not(.speed-comparison__item--active)
    > .speed-comparison__card
    > .speed-comparison__item-subtitle {
    display: none;
}

:not(.speed-comparison__item--active)
    > .speed-comparison__card
    > .achievement__items {
    padding-block: 0rem;
}

:not(.speed-comparison__item--active) > .speed-comparison__total-volume {
    display: none;
}

:not(.speed-comparison__item--active)
    > .speed-comparison__card
    > .achievement__items
    > .achievement__recent
    img {
    width: clamp(2rem, 1.7rem + 1.5vw, 3.5rem);
}

.achievement__desc {
    margin-bottom: 0.5rem;
}

.speed-comparison__total-volume strong {
    font-size: var(--text-500);
}
