/* Custom Scrollbars */

@media (hover: hover) {
    body::-webkit-scrollbar {
        width: 1rem;
    }

    body::-webkit-scrollbar-track {
        background-color: hsl(210, 10%, 92%);
    }

    body::-webkit-scrollbar-thumb {
        background-color: #3133ff;
        background: linear-gradient(
            45deg,
            hsl(239, 100%, 60%) 25%,
            hsl(198, 100%, 67%) 75%
        );
        border-radius: 10px;
    }
}
