.promo-cards__list {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
}

.promo-cards__lead-image {
    aspect-ratio: 4/3;
}

@media only screen and (min-width: 768px) {
    .promo-cards__list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1024px) {
    .promo-cards__list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.promo-cards__default h2 {
    font-size: var(--text-600);
    line-height: var(--spacing-600);
    margin-bottom: 1.5rem;
    font-weight: 900;
}

.promo-cards__default .price-grid {
    --price-size: 1.25;
}

.promo-cards__default .price-grid--inline {
    margin-bottom: 2rem;
}

.promo-cards__inlinecard {
    box-shadow: var(--default-box-shadow);
    background-color: var(--background-light);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 340px;
    text-align: center;
}

.promo-cards__covercard {
    box-shadow: var(--default-box-shadow);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 340px;
    text-align: center;
    position: relative;
    background: hsl(222deg 83% 37%/59%);
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 18rem;
    overflow: hidden;
    /* isolation: isolate; */
    /* -webkit-backface-visibility: hidden; */
    /* -moz-backface-visibility: hidden; */
    /* -webkit-transform: translate3d(0, 0, 0); */
    /* -moz-transform: translate3d(0, 0, 0); */
    /* position: relative; */
    /* z-index: 1; */
    /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
    /* opacity: 0.999; */
}

.promo-cards__default {
    box-shadow: var(--default-box-shadow);
    /* background-color: var(--background-light); */
    /* text-align: center; */
    border-radius: 2rem;
    /* flex-direction: column; */
    /* flex: 0 0 340px; */
    /* justify-content: center; */
    /* align-items: center; */
    /* display: flex; */
    overflow: hidden;
}

/* .promo-cards__default .promo-cards__content-header {
  margin-inline: -2rem;
  margin-block-start: -1rem;
} */

.promo-cards__default .promo-cards__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    /* overflow: hidden; */
}

.promo-cards__default .promo-cards__content-body {
    /* justify-content: space-around; */
    height: 100%;
}

.promo-cards__default .promo-cards__content-header {
    margin-bottom: 2rem;
}

.promo-cards__default .promo-cards__content-body {
    margin-inline: 1.5rem;
}

.promo-cards__default .promo-cards__content-footer {
    margin-inline: 1.5rem;
    margin-block-start: 0.5rem;
    margin-block-end: 2rem;
}

.promo-cards__content {
    padding: 2rem;
}

.promo-cards__covercard-wrapper {
    z-index: -1;
    /* overflow: hidden; */
}

.promo-cards__covercard .promo-cards__content {
    color: white;
}

.promo-cards__covercard-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100vw;
    border-radius: 2rem;
}

/* Order Cards */

.order__subtitle {
    font-size: var(--text-600);
    margin-bottom: 0;
}

.order__step-title {
    font-size: var(--text-500);
    margin-bottom: var(--spacing-500);
}

.order__card {
    padding: 1.5rem;
    background-color: var(--background);
}

.order__select-button {
    margin-top: 1rem;
}

.order__product-title {
    margin-block-end: 0;
    font-size: var(--text-600);
    font-weight: 900;
    margin-bottom: 1rem;
}

.order__product-title--mixed-bold {
    font-weight: 400;
    line-height: 1.2;
}

.order__product-image {
    margin-bottom: 1rem;
}

/* Order Product Specs */

.order__product-spec {
    display: flex;
    gap: 0.4rem;
    font-weight: 900;
}

/* Order Product Specs - Labeled */

.order__product-spec--labeled {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-block: 0.3rem;
}

.order__product-spec-value {
    font-size: var(--text-400);
}

.order__product-spec-label {
    font-size: var(--text-200);
    font-weight: 400;
}

.order__product-oldprice {
    font-size: 0.875rem;
    color: hsla(0, 0%, 0%, 0.4);
}

.order__options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order__options-title {
    font-weight: 900;
}

.promo-cards__category-title {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.promo-cards__category-title>* {
    margin-bottom: 0;
    font-size: var(--text-300);
    color: var(--primary-color);
    font-weight: 600;
}

.promo-cards__category-title {
    margin-bottom: 0.5rem;
    letter-spacing: 0.0125rem;
    align-items: center;
}

/* .order__step-content {
  display: none;
}

.order__step--active .order__step-content {
  display: block;
} */

.order__step-content {
    max-height: 0;
    height: min-content;
    transition: max-height 250ms ease-out, opacity 250ms ease-out,
        transform 250ms ease-out;
    overflow: visible;
    transform: translateY(60px);
    opacity: 0;
    pointer-events: none;
}

.order__step--active .order__step-content {
    max-height: 1425px;
    transition: max-height 250ms ease-in, opacity 250ms ease-in,
        transform 250ms ease-in;
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
}

/* .order__step--done .order-step__headline {
  color: var(--primary-color);
} */

.order-summery__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

/* Hide Detailed One Time Prices on Mobile */

@media only screen and (max-width: 560px) {
    .order-summery__header {
        display: flex;
        justify-content: end;
    }

    .order-summery__detail-legend-label--onetime {
        display: none;
    }

    .order-summery__price--onetime {
        display: none;
    }
}

.order__summery {
    display: flex;
    flex-direction: column;
}

.order__summery:last-child {
    align-items: end;
}

.summery__price--total {
    font-size: var(--text-600);
    font-weight: 900;
}

.summery__price--label {
    font-size: var(--text-300);
}

.summery__item {
    display: flex;
    justify-content: space-between;
}

.order-summery__total {
    display: flex;
    gap: 1rem;
    /* flex-basis: calc(300px + 1rem); */
}

.order-summery__price--total {
    font-size: var(--text-600);
    font-weight: 900;
}

.order-summery__total--onetime .order-summery__price--total {
    font-size: var(--text-500);
    /* font-weight: 300; */
}

.order-summery__total--onetime .order-summery__price--label {
    font-size: var(--text-200);
    /* font-weight: 500; */
}

.order-summery__total--onetime {
    display: flex;
    flex-direction: column;
    align-items: end;
    align-self: end;
    /* opacity: 0.75; */
}

.order-summery__total--monthly {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.order-summery__detail-legend {
    display: flex;
    gap: 1rem;
    justify-content: end;
}

.order-summery__items {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
}

.order-summery__item {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    padding-block: 0.5rem;
}

.order-summery__item {
    border-bottom: 1px solid #ccc;
}

.order-summery__item:last-child {
    border-bottom: none;
}

.order-summery__footer {
    display: flex;
    gap: 1rem;
    justify-content: end;
    flex-wrap: wrap;
}

.order-summery__total--monthly,
.order-summery__price--monthly,
.order-summery__detail-legend-label--monthly,
.order-summery__price--onetime,
.order-summery__total--onetime,
.order-summery__detail-legend-label--onetime {
    /* flex-basis: 150px; */
    width: clamp(7rem, 6.4rem + 3vw, 10rem);
    text-align: right;
}

.order-summery__title {
    flex: 1 0 auto;
    font-weight: 600;
}

.order-summery__detail-legend-label--onetime,
.order-summery__detail-legend-label--monthly,
.order-summery__price--label {
    font-size: var(--text-300);
}

.order-summery__detail-legend {
    margin-bottom: 1rem;
}

#bundleconfigurator_step_summary .order__step-content {
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
}

.order-step__headline {
    display: flex;
    align-items: baseline;
    margin-bottom: 1.5rem;
    gap: 0.5rem;
}

.order-step-headline__title {
    margin-bottom: 0;
    font-size: var(--text-500);
    transition: font-size 250ms ease;
}

.order-step-headline__edit {
    margin-left: auto;
}

.order__checked-marker {
    display: none;
    width: var(--text-500);
    height: var(--text-500);
    color: var(--primary-color);
    top: 0.3rem;
    position: relative;
}

.order__step--done .order__checked-marker {
    display: block;
}

.order__step .order-step-headline__title {
    opacity: 0.5;
}

.order__step--active .order-step-headline__title {
    font-size: var(--text-600);
    opacity: 1;
}

.order__step--done .order-step-headline__title {
    font-size: var(--text-500);
    opacity: 1;
}

.order__step--active.order__step--done .order-step-headline__title {
    font-size: var(--text-600);
}

.order__edit-marker {
    width: var(--text-500);
    height: var(--text-500);
    color: var(--primary-color);
    top: 0.3rem;
    position: relative;
}

/* Show / Hide Edit Buttons */

.order__step .order-step-headline__edit {
    display: none;
}

.order__step--active .order-step-headline__edit {
    display: none;
}

.order__step--done .order-step-headline__edit {
    display: block;
}

.not-available {
    filter: saturate(0%);
    opacity: 0.3;
}

.not-available .order__select-button {
    display: none;
}