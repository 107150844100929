/* Footer */

footer {
    color: currentColor;
}

footer a {
    color: currentColor;
}

footer svg {
    fill: currentColor;
}

footer ul {
    padding-left: inherit;
    list-style: none;
}

footer a {
    transition-property: opacity;
    opacity: 0.75;
    text-decoration: none;
}

footer a:hover {
    opacity: 1;
    color: inherit;
}

.footer__top {
    background: linear-gradient(-269deg, #0c095e, #1045db);
    color: white;
}

.footer__top .container {
    padding-block: clamp(1.125rem, 0.5rem + 3.125vw, 3rem) 1.5rem;
    padding-inline: var(--default-inline-padding);
    max-width: 76rem;
}

.footer__middle {
    background: linear-gradient(-269deg, #0c095e, #1045db);
    color: white;
}

.footer__middle .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    row-gap: 1rem;
    column-gap: 2rem;
    padding-block: 1.5rem;
    padding-inline: var(--default-inline-padding);
    max-width: 76rem;
}

.footer__bottom {
    color: #000;
    background: var(--background-light);
    font-size: 0.9rem;
    font-weight: 500;
}

.footer__bottom .container {
    padding-block: 1.5rem clamp(1.125rem, 0.5rem + 3.125vw, 3rem);
    padding-inline: var(--default-inline-padding);
    max-width: 76rem;
}

.footer__legal ul {
    flex-wrap: wrap;
}

.footer__social {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    align-self: center;
}

.footer__bottom-row {
    display: flex;
    row-gap: 1rem;
    column-gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer__disclaimer {
    margin-bottom: 1.5rem;
    opacity: 0.75;
}

.footer__disclaimer p {
    margin-bottom: 0;
}

.footer__sitemap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    gap: 1rem;
}

.footer__legal {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 0.85rem;
    flex-wrap: wrap;
}

.footer__languages {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 1rem;
    align-items: center;
}

.footer__language--active {
    opacity: 1;
}
