/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

/**
 * Simple default styles for headings 1 through 6.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    /* max-width: 38ch; */
}

h1 {
    font-size: var(--text-800);
}
h2 {
    font-size: var(--text-700);
    margin-bottom: var(--spacing-700);
}
h3 {
    font-size: var(--text-600);
    margin-bottom: var(--spacing-700);
}
h4 {
    font-size: var(--text-500);
}
h5 {
    font-size: var(--text-400);
}
h6 {
    font-size: var(--text-300);
}

.title {
    font-size: var(--text-700);
    margin-bottom: var(--spacing-700);
    line-height: 1.2;
}

.subtitle {
    font-size: var(--text-600);
    margin-bottom: var(--spacing-500);
    line-height: 1.2;
}

.title + .subtitle {
    margin-top: calc(var(--spacing-600) * -1);
    margin-bottom: var(--spacing-700);
}

.hero__title + .hero__subtitle {
    margin-top: calc(var(--spacing-600) * -1);
    margin-bottom: var(--spacing-700);
}

.hero__title {
    color: #2e54a1;
    margin-bottom: 2rem;
    font-weight: 900;
    font-size: clamp(2.6rem, 2.22rem + 1.9vw, 4.5rem);
    line-height: 1.2;
}

.hero__title b {
    color: #14afe6;
}

.hero__subtitle {
    font-size: clamp(1.6rem, 1.52rem + 0.4vw, 2rem);
    line-height: 1.2;
    color: #2e54a1;
    margin-bottom: 2rem;
    max-width: 25ch;
    font-weight: 600;
}

.hero__subtitle b {
    color: #14afe6;
}

.list-item__title {
    font-size: var(--text-500);
    font-weight: 700;
    margin-bottom: var(--spacing-400);
}

.list-item__subtitle {
    font-size: var(--text-500);
    font-weight: 400;
    margin-bottom: var(--spacing-400);
}

.section--order-questions .list-item__title {
    font-size: var(--text-600);
}

.section--order-questions .list-item__subtitle {
    margin-bottom: 0;
}

.promo-cards__hero-title {
    font-size: var(--text-700);
    margin-bottom: var(--spacing-400);
}

.promo-cards__title {
    font-size: var(--text-600);
    margin-bottom: var(--spacing-400);
}

/* Text Marker Colors and Link Colors */

.span-blue {
    --primary-text-decoration-color: var(--primary-color-300);
    --primary-link-color: var(--primary-color);
    color: var(--primary-color);
}

.span-orange {
    --primary-text-decoration-color: var(--secondary-color-300);
    --primary-link-color: var(--secondary-color);
    color: var(--secondary-color);
}

/* Page Title */
.header-block__title {
    font-size: var(--text-700);
    line-height: var(--spacing-700);
}

/* Highlight Colors for Spans */

.span-primary {
    color: var(--primary-color);
}
.span-secondary {
    color: #fcd01e;
}
.span-accent {
    color: #32faca;
}
.span-alternative {
    color: #735ce6;
}
