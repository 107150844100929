/* Aligning Text */

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
    align-items: center;
}

.text-justify {
    text-align: justify;
}

/* Aligning Items */

.align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.align-left {
    display: block;
    margin-right: auto;
}

.align-right {
    display: block;
    margin-left: auto;
}

/* Position Items */

.relative {
    position: relative;
}

/* Layout Items */

.flex {
    display: flex;
    gap: 1rem;
}

.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
}

/* Format Items */

.no-margin {
    margin: 0;
}

.muted {
    opacity: 0.75;
}

.ghost {
    visibility: hidden;
}

/* Hide and Show Elements */

.show {
    display: block;
}

.hide,
.ninja,
.keendoheem {
    display: none;
}

/* Flex Even Columns */

.flex-columns-even {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-block-end: 1.5rem;
}

/* loading spinner */

.loading {
    color: transparent !important;
    position: relative;
    pointer-events: none;
}

.loading:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -.64285714em 0 0 -.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    border-radius: 500rem;
    border: .2em solid rgba(0, 0, 0, .15);
}

/* blur element */

.blur {
    opacity: .5;
    filter: blur(5px);
    pointer-events: none;
}
