body,
html {
  height: 100%; /* Allow percentage-based heights in the application */
  margin: 0;
}

html {
  overflow: auto;
  font-size: 1rem; /* Define minimum font size */
}

body {
  overflow-x: hidden;
  font-family: var(--MainFont);
  font-size: var(--text-400); /* Fluid Type for base elements */
  background-color: var(--background);
  line-height: var(--default-line-height);
  padding: 0;
  text-rendering: optimizeLegibility;
  cursor: auto;
  scroll-behavior: smooth;
  position: relative;
  -webkit-font-smoothing: antialiased; /* subpixel antialiasing fix for macos */
}
