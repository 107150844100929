:root {
    --channel-logo-marquee-size: clamp(7rem, 6.2rem + 4vw, 11rem);
}

.channels {
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: clamp(1rem, 0.9rem + 0.5vw, 1.5rem);
}

.channel {
    background-color: white;
    padding: clamp(1rem, 0.8rem + 1vw, 2rem);

    min-width: var(--channel-logo-marquee-size);
    height: var(--channel-logo-marquee-size);

    margin-left: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
    margin-right: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
        0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

.channel__headline {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 360px;
}
.channel__description {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 300px;
}

.section__background--light {
    overflow-x: hidden;
}

.marquee {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.marquee__track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee-horizontal 6s linear infinite;
}

.marquee--horizontal {
    position: relative;
    display: flex;
    overflow: hidden;
}

.marquee__cover--horizontal {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 10;
    background-image: linear-gradient(
            270deg,
            hsla(0, 0%, 100%, 0) 70%,
            var(--background-light)
        ),
        linear-gradient(
            270deg,
            var(--background-light),
            hsla(0, 0%, 100%, 0) 30%
        );
}

.container--fullwidth {
    max-width: 100%;
}

.container--flex {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0rem;
    column-gap: 2rem;
    flex-direction: row;
}

.flex-horizontal {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-vertical {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.marquee__track--horizontal {
    will-change: transform;
    animation: marquee--horizontal 60s linear infinite;
    padding-bottom: clamp(0.5rem, 0.2rem + 1.5vw, 2rem);
}

.marquee__track--horizontal-alt {
    will-change: transform;
    padding-bottom: clamp(0.5rem, 0.2rem + 1.5vw, 2rem);
    animation: marquee--horizontal-alt 60s linear infinite;
}

@keyframes marquee--horizontal {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes marquee--horizontal-alt {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0%);
    }
}

.section--channels .container {
    /* margin: 0; */
}

.section--channels .container--fullwidth {
    /* margin: 0; */
    padding-top: 0;
}
