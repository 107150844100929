.swiper {
    margin-bottom: 4rem;
    overflow: revert;
    overflow-x: clip;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: var(--text);
}

.swiper .card {
    border-radius: 2rem;
    align-self: end;
    box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013,
        0 8px 8px #00000013, 0 16px 16px #00000013;
}

.order__card {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #ffffff, #ffffff) border-box;
    border: 4px solid transparent;
}

.order__card p {
    margin-bottom: 1rem;
}

.swiper-slide-active {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #6c42e4, #00b0ea) border-box;
    border-radius: 50em;
    border: 4px solid transparent;
}

.swiper-horizontal>.swiper-pagination-bullets {
    text-align: center;
}

.card-bullet-title {
    cursor: pointer;
    margin-inline: 0.4rem;
    text-transform: uppercase;
    font-weight: 500;
    transition: color 150ms;
}

.card-bullet-active-title {
    color: var(--primary-color);
}

.swiper-horizontal>.swiper-pagination-bullets {
    margin-block: 1rem;
    bottom: auto;
    display: flex;
    justify-content: center;
}

/* Swiper Promo Card Styles */

.order__card--promo {
    background: linear-gradient(to right, #6c42e4, #00b0ea) padding-box,
        linear-gradient(to right, #6c42e4, #00b0ea) border-box;
    border-radius: 50em;
    border: 4px solid transparent;
    color: white;
}

.order__card--promo .order__product-oldprice {
    color: white;
}

.order__card--promo use {
    --arrowColor: rgb(0, 238, 255);
}

use {
    --arrowColor: var(--primary-color);
}

.bundleconfigurator__activation {
    text-decoration: none;
    transform: translateY(-0.75rem);
    font-size: var(--text-300);
}

.bundleconfigurator__activation--strikedthrough {
    text-decoration: line-through;
    opacity: 0.8;
}

.swiper .button__container {
    flex-grow: 1;
    align-content: end;
}

.swiper .order__card-option {
    min-height: 300px;
}
