.language-switcher__overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 20, 50, 0.2);
  opacity: 0;
  transition: opacity 0.125s ease-out 0s;
  will-change: opacity;
  overflow-y: auto;
  z-index: 100;
}

.language-switcher__overlay.active {
  animation-name: overlayOpenDefault;
  animation-duration: 0.125s;
  animation-fill-mode: forwards;
  opacity: 1;
}

.language-switcher__content {
  border-radius: 0.25rem;
  overflow-y: auto;
  background: rgb(255, 255, 255);
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 250ms ease 0s;
  will-change: opacity, transform;
  padding: clamp(1rem, 0rem + 5vw, 3rem);
}

.language-switcher__overlay.active .language-switcher__content {
  animation-name: contentOpenDefault;
  animation-duration: 0.125s;
  animation-fill-mode: forwards;
  opacity: 1;
  transform: scale(1);
}

.language-switcher__languages {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.language-switcher__language {
  font-size: 0.875rem;
  margin: 0px;
  font-weight: 400;
  border-radius: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  padding: 0px 0.3125rem;
  box-shadow: rgb(10 10 130 / 8%) 0px 2px 16px 0px,
    rgb(10 10 130 / 16%) 0px 1px 2px 0px;
  transition: all 0.25s ease-out 0s;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  color: rgb(10, 10, 130);
  background-image: none;
  background-color: rgb(255, 255, 255);
  border: 0.125rem solid transparent;
}

.language-switcher__language:hover,
.language-switcher__language:focus {
  background-color: rgb(255, 255, 255);
  color: rgb(20, 21, 250);
  box-shadow: rgb(10 10 130 / 8%) 0px 6px 32px 0px,
    rgb(10 10 130 / 8%) 0px 4px 16px 0px, rgb(10 10 130 / 24%) 0px 1px 4px 0px,
    rgb(217 220 231 / 20%) 0px 0px 0px 0.75rem;
}

.language-switcher__language--active {
  border-color: rgb(20, 21, 250);
  box-shadow: rgb(10 10 130 / 8%) 0px 6px 32px 0px,
    rgb(10 10 130 / 8%) 0px 4px 16px 0px, rgb(10 10 130 / 24%) 0px 1px 4px 0px,
    rgb(10 10 130 / 9%) 0px 4px 96px 0px, rgb(10 10 130 / 8%) 0px 2px 16px 0px,
    rgb(10 10 130 / 24%) 0px 1px 2px 0px;
}

@keyframes contentOpenDefault {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes overlayOpenDefault {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
