.pill {
    letter-spacing: 0.035rem;
    background-color: orange;
    border-radius: 1rem;
    color: white;
    padding-inline: 0.9rem;
    padding-block: 0.1rem 0.2rem;
    font-weight: 600;
    align-items: center;
    display: inline-flex;
}

.pill--tiny {
    font-size: var(--text-200);
    padding-inline: 0.8rem;
}

.pill--small {
    font-size: var(--text-300);
    padding-inline: 0.8rem;
}

.pill--alternative,
.pill--unavailable {
    background-color: #fff2f2;
    color: #ef0000;
}

.pill--tertiary,
.pill--success,
.pill--available {
    background-color: #ccedd2;
    color: #00a721;
}

.pill--secondary {
    background-color: #ffedcc;
    color: #ffa500;
}

.pill--primary {
    background: linear-gradient(to right, #6c42e4, #00b0ea);
    color: #ffffff;
}

/* News Pills */
.pill--private-news {
    background-color: #d6f7f9;
    color: #001f5c;
}
.pill--business-news {
    background-color: #eee0f7;
    color: #9122c2;
}
.pill--hosting-news {
    background-color: #fff2f2;
    color: #ef0000;
}
.pill--company-news {
    background-color: #ffedcc;
    color: #ffa500;
}

.order__card .pill {
    font-size: var(--text-300);
    margin-bottom: 0.5rem;
}

.order__card--promo .pill--primary {
    background: linear-gradient(to right, #00b0ea, #00b0ea);
}

.pill--manufacturer {
    background: linear-gradient(to right, #00b0ea, #00b0ea);
}
