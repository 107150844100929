/*------------------------------------*\
  #RESET

  Credits:
  https://www.joshwcomeau.com/css/custom-css-reset/
  https://bradfrost.com/

\*------------------------------------*/

/**
 * Remove default margin
 */

* {
    margin: 0;
}

/**
 * Zero out padding on these elements.
 */

html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure,
main {
    padding: 0;
}

/**
 * Remove list styles from ordered and unordered lists by default
 */

ol,
ul {
    list-style: none;
}

/**
 * Collapse borders on table's base styles
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/**
 * Display block these elements
 */

header,
footer,
nav,
section,
article,
hgroup,
figure,
main {
    display: block;
}

/*
  Improve media defaults
*/

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
    height: auto;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
    font-family: inherit;
}

/*
 Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

@media screen and (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
    }
}
