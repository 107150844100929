/* Default Table Styles */

table {
    border-collapse: collapse;
    margin: 1em 0em 3em 0em;
    width: 100%;
    font-weight: 500;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0);
}

table p {
    margin: 0;
}

table caption {
    font-weight: bold;
    text-align: left;
    font-size: 1.4em;
    padding: 1em 0;
    color: black;
    background-color: white;
    display: none;
}

td,
th {
    border-bottom: 2px solid #dadada;
    padding: 0.5rem;
}

th {
    font-weight: 600;
}

tfoot tr:last-child td {
    border-bottom: none;
}

/* Table Header Basic Styles */

th {
    color: black;
    background-color: #fff;
}

/* Table Header Row Background Colors */

tr th[scope="row"] {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 0px;
}

tr {
    background-color: #fff;
}

/* Table Body Row Background Colors */

tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0);
}

tr:nth-child(odd) {
    background-color: var(--light-backgroundcolor);
}

/* Table Body Hover Stripe Color */

tbody tr td {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: border-bottom 0.2s linear;
}

tbody tr:hover td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

/* Table Footer Basic Styles */

tfoot tr {
    font-weight: bold;
    color: black;
}

/* Custom Styles for Telephony Price List */

.table--telephony-pricelist thead {
    font-weight: bold;
    font-size: var(--text-500);
}
