header {
    overflow: hidden;
}

.page {
    padding-top: clamp(3rem, 26vw - 2.2rem, 16rem);
    padding-bottom: clamp(4rem, 3.2rem + 4vw, 8rem);
}

.page__content {
    margin-block-start: clamp(2.5rem, 1.4rem + 5.5vw, 8rem);
    margin-block-end: clamp(2.5rem, 1.4rem + 5.5vw, 8rem);
}

.page__content .content-stream {
    padding-block: 0;
}

.split {
    display: flex;
    column-gap: 2rem;
    flex-direction: column;
}

@media (min-width: 40rem) {
    .split {
        flex-direction: row;
    }

    .split>* {
        flex-basis: 100%;
    }
}

/* Page Templates */

.page-template {
    background-color: aliceblue;
}

.streamfield__tictoc {
    padding-block: clamp(1.5rem, 1.2rem + 1.5vw, 3rem);
}

.streamfield__tictoc--image img {
    margin-bottom: 1.5rem;
}

.header-block {
    margin-inline: auto;
    width: min(100% - 2.25rem, 50rem);
}

.streamfield__intro .rich-text {
    margin-inline: auto;
    width: min(100%, 50rem);
}

.streamfield__default>*,
h2.streamfield__default,
h3.streamfield__default,
h4.streamfield__default {
    margin-inline: auto;
    width: min(100%, 50rem);
}

.streamfield__wide>*,
h2.streamfield__wide,
h3.streamfield__wide,
h4.streamfield__wide {
    margin-inline: auto;
    width: min(100%, 76rem);
}

.streamfield__fullwidth>*,
h2.streamfield__fullwidth,
h3.streamfield__fullwidth,
h4.streamfield__fullwidth {
    margin-inline: auto;
    width: min(100%, 160rem);
}

.streamfield__fullwidth>img {
    margin-inline: auto;
    width: min(100%, 160rem);
    max-height: 640px;
}

.streamfield__default>img {
    width: unset;
}

.header-block__title {
    margin-bottom: 3rem;
}

.streamfield__intro {
    font-size: var(--text-500);
    padding-block: 1.5rem;
}

.streamfield__heading,
.streamfield h2 {
    margin-bottom: 1.5rem;
    font-size: var(--text-600);
}

.streamfield__image {
    margin-bottom: 1.5rem;
}

.streamfield__tictoc h3:first-child {
    margin-top: 0;
}
