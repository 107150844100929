/*------------------------------------*\
  #SHARED
\*------------------------------------*/

/**
 * Shared declarations for certain elements.
 */

h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dd,
p,
figure,
pre,
table,
fieldset,
hr {
    margin-bottom: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
    overflow-wrap: break-word; /* Help prevent overflow of long words/names/URLs */
    /* hyphens: auto; Optional, not supported for all languages */
}
