/* Footnote Styles */

.footnotes {
    counter-reset: li;
    color: hsl(0deg 0% 50% / 100%);
    padding-left: 0;
    font-size: var(--text-300);
    text-align: left;
}

.footnotes > li {
    position: relative;
    margin-left: 1em;
    padding-left: 0.5em;
    list-style: none;
}

.footnotes > li:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1em;
    width: 1em;
    font-weight: 600;
    text-align: center;
    font-size: 0.75em;
    top: 0.1em;
}

.footnotes p {
    margin-block-end: clamp(0.3rem, 0.26rem + 0.2vw, 0.5rem);
}

.footnote__link--pill {
    text-decoration: none;
}

.footnote__link--pill .pill {
    display: flex;
    gap: 0.25rem;
}

.footnote__link--pill .pill sup {
    transform: translateY(-0.25rem);
    font-weight: 300;
    opacity: 0.75;
}
