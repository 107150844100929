button,
[type="button"],
[type="reset"],
[type="submit"] {
    outline: none;
}

.button,
button,
.btn {
    border-radius: 2rem;
    font-family: inherit;
    text-align: center;
    position: relative;
    overflow: visible;
    border-color: currentColor;
    text-transform: none;
    min-width: 100px;
    justify-content: center;
    font-size: var(--text-400);
    border-style: solid;
    border-width: 2px;
    padding: 0.4rem 1.2rem 0.5rem;
    cursor: pointer;
    display: inline-block;
    background-color: transparent;
    text-decoration: none;
    color: currentColor;
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: 600;
}

.button,
button,
.btn {
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-duration: 100ms;
    will-change: transform;
    transition-property: color, background-color, border-color, transform,
        box-shadow;
}

.button:hover,
button:hover,
.btn:hover {
    box-shadow: black 3px 3px 0px;
    transform: translate(-3px, -3px);
    color: black;
    border-color: black;
    background-color: hsla(0, 0%, 100%, 1);
}

button.button--tiny,
.button--tiny {
    font-size: var(--text-200);
    padding: 0.5rem 1rem;
}

button.button--small,
.button--small {
    font-size: var(--text-300);
    padding: 0.25rem 0.8rem;
}

button.button--large,
.button--large {
    font-size: 1.4em;
    padding: 0.6rem 1.2rem;
}

button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

button[disabled]:active {
    pointer-events: none;
}

button[disabled]:hover {
    transform: none;
    box-shadow: none;
    border-color: #00000025;
}

button.has-icon {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    position: relative;
}

button.languages-switcher-menu {
    padding: 0;
    min-width: auto;
    border-width: 0;
    padding: 0;
    border: none;
    transition-property: color;
    transition-duration: 0.2s;
    font-size: var(--text-300);
    align-items: center;
}

button.languages-switcher-menu:hover,
.language-menu a:hover {
    color: var(--primary-color);
    box-shadow: none;
    transform: none;
    background-color: transparent;
}

button.languages-switcher-menu:focus {
    background: transparent;
    box-shadow: none;
}

button.languages-switcher-menu:focus-visible {
    outline-color: rgb(16, 16, 16);
    outline-offset: 1px;
    outline-style: auto;
    outline-width: 1px;
}

.block-call_to_action_button .icon {
    width: 1.3rem;
    height: 1.3rem;
}

.order__select-button .icon {
    width: 1.3rem;
    height: 1.3rem;
}

button.btn--text-link {
    font-size: var(--text-300);
    border: 0;
    padding: 0;
    gap: 0.75rem;
    text-decoration: underline;
    text-decoration-color: var(--primary-text-decoration-color);
    transition: text-decoration-color 0.2s;
}

button.btn--text-link:hover {
    box-shadow: none;
    transform: none;
    color: var(--primary-link-color);
    text-decoration-color: var(--primary-color);
    background-color: transparent;
}

button.order-summary__button--primary {
    color: var(--primary-color);
}

button.order-summary__button--primary:hover {
    border-color: var(--primary-color);
    box-shadow: 3px 3px var(--primary-color);
}
