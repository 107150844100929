.related-documents__list {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
}

@media only screen and (min-width: 640px) {
    .related-documents__list {
        grid-template-columns: 1fr 1fr;
    }
}

.related-documents__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.related-documents__link {
    text-decoration: none;
}

.related-documents__list .icon {
    color: hsla(255, 0%, 0%, 0.8);
}

.related-documents__title {
    color: hsla(0, 0%, 0%, 1);
}

.section--related-documents {
    padding: var(--section-padding);
    background-color: var(--background-light);
}

.related-documents__item {
    background-color: hsl(0, 0%, 100%);
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 2px 5px -1px rgb(50 50 93 / 25%),
        0 1px 3px -1px rgb(0 0 0 / 30%);
    transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
    will-change: transform;
}

.related-documents__item:hover {
    box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
        0 8px 16px -8px rgb(0 0 0 / 30%);
    transform: translateY(-5px);
    background-color: hsl(191, 100%, 92%);
}

/* .related-documents__item:hover::before {
    filter: blur(0.75em);
}

.related-documents__item:hover::before,
.related-documents__item:hover::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: inherit;
}

.related-documents__item:hover::after {
    opacity: 0.5;
    filter: blur(2em);
} */

.related-documents__list + h3 {
    margin-block-start: var(--spacing-700);
    margin-block-end: var(--spacing-600);
}
