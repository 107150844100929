.hero {
    min-height: 350px;
}

/* .container {
  width: min(100% - 2rem, 50rem);
  margin-inline: auto;
} */

.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
}

.options-list {
    display: flex;
    flex-wrap: wrap;
}

/* Main */

main:focus {
    outline: none;
}

/* Base Container */

.container {
    margin: 0 auto;
    max-width: 64rem;
    width: 100%;
}

.container--narrow {
    max-width: 60rem;
}

.container--wide {
    max-width: 80rem;
}

.container--note {
    text-align: left;
    max-width: 32rem;
    font-size: var(--text-300);
    margin-inline: auto;
}

/* Highligh-Color by clicking and dragging the mouse across text */

::selection {
    color: currentColor;
    background: rgb(215, 248, 255);
}

/* Rows */

.row {
    margin: 0 auto;
    max-width: 64rem;
    width: 100%;
}

.row-wide {
    max-width: 80rem;
}

.row-ultrawide {
    max-width: 112rem;
}

.row-fullwidth {
    max-width: 100%;
}

/* Columns */

.column,
.columns {
    position: relative;
    width: 100%;
}

/* Restrict Article Width to 76ch */

.article .row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article .column,
.article .columns {
    max-width: 76ch;
}

/* Main Content */

.homepage #vo-content {
    padding-top: 0em;
}

#vo-content {
    padding-top: 8em;
}

@media only screen and (min-width: 641px) {
    #vo-content {
        padding-top: 0em;
    }
}
